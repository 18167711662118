import { ReactNode } from 'react';

interface Props<T> {
  value: T | null | undefined;
  formatter?: (value: NonNullable<T>) => ReactNode;
}

export function NullableCell<T>({ value, formatter }: Props<T>): ReactNode {
  return <>{(value != null && formatter ? formatter(value) : value) ?? '---'}</>;
}
