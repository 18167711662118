import { QuoteStatus } from '../../__enums__/QuoteStatus';
import { QuoteKind } from '../../__enums__/QuoteKind';
import { TransportKind } from '../../__enums__/TransportKind';
import { LoadingUnloading } from '../../__enums__/LoadingUnloading';
import { TransportSegmentKind } from '../../__enums__/TransportSegmentKind';
import { CalculatorFlatbedBoomCounterweightTransportEntryKind } from '../../__enums__/CalculatorFlatbedBoomCounterweightTransportEntryKind';
import { AdjustmentSegmentKind } from '../../__enums__/AdjustmentSegmentKind';
import { AdjustmentUnit } from '../../__enums__/AdjustmentUnit';

export const quote = {
  addenda: 'Addenda',
  alert: {
    supervisionComment: 'Supervision Comment',
    costLinesAdded_one: '{{count}} cost line have been added',
    costLinesAdded_other: '{{count}} cost lines have been added',
  },
  arialLabels: {
    main: 'Create operated crane (hourly) quote',
    more: 'Show more options',
    options: {
      accessoriesRental: 'Create accessories rental quote',
      bare: 'Create bare crane quote',
      laborRental: 'Create labor rental quote',
      liftingPlan: 'Create lifting plan quote',
      liftingTest: 'Create lifting test quote',
      liftingTestOperated: 'Create lifting test operated quote',
      operatedHourly: 'Create operated crane (hourly) quote',
      operatedMonthly: 'Create operated crane (monthly) quote',
      rollingEquipment: 'Create rolling equipment quote',
      bareWithOperators: 'Create bare crane with operators quote',
      storage: 'Create storage quote',
    } satisfies Record<QuoteKind, string>,
  },
  button: {
    approve: 'Approve',
    decline: 'Decline',
    modify: 'Modify',
    returnToInWriting: 'Return in writing',
    rejectBySupervisor: 'Reject',
    accept: 'Accept',
  },
  comment: 'Comment',
  costs: 'Costs',
  dateTime: {
    sevenDays: '7 days',
    fourteenDays: '14 days',
    thirtyDays: '30 days',
    sixtyDays: '60 days',
  },
  dialog: {
    cancel: {
      title: 'Cancel Quote',
      confirmButton: 'Cancel Quote',
      body: 'Are you sure you want to cancel this quote? Any unsaved changes will be lost. This operation is irreversible.',
    },
    copy: {
      title: 'Copy Quote',
      confirmButton: 'Copy Quote',
    },
    email: {
      title: 'Send by Email',
      sendToProjectManager: 'Project Manager',
      sendToClientRepresentative: 'Client Representative',
      sendToCurrentUser: 'Logged-in User',
      sendButton: 'Send',
      unavailable: 'unavailable',
      error: 'An error occurred while sending the email.',
    },
    accept: {
      title: 'Accept Quote',
      body: 'Are you sure you want to accept this quote? This operation is irreversible. One or multiple service calls will be automatically created.',
      cancelButton: 'No',
      confirmButton: 'Yes',
    },
    decline: {
      title: 'Decline Quote',
      body: 'Are you sure you want to decline this quote? This operation is irreversible.',
      cancelButton: 'No',
      confirmButton: 'Yes',
    },
  },
  error: {
    errorDuringDeclineByClient: 'An error occurred while declining',
    errorDuringAccept: 'An error occurred while accepting',
    errorDuringRequestChange: 'An error occurred while requesting changes',
    errorDuringRequestReview: 'An error occurred while requesting approval',
    errorDuringRejectBySupervisor: 'An error occurred while rejecting',
    errorDuringApproveBySupervisor: 'An error occurred while approving',
    errorDuringReturnToInWriting: 'An error occurred while returning to in writing',
    clientIsRequired: 'Please select a client',
    worksiteIsRequired: 'Please select a worksite',
  },
  field: {
    accessory: {
      accessoryLine_one: '{{ count }} accessory line',
      accessoryLine_other: '{{ count }} accessory lines',
      accessory: 'Accessory',
      billed: 'Billed',
      noAccessory: 'No Accessory',
      overline: 'Accessories',
      qty: 'Qty.',
      type: 'Accessory Type',
      billable: 'Billable',
      quantity: 'Quantity',
      rate: 'Rate',
    },
    addenda: {
      addenda_one: '{{ count }} addendum',
      addenda_other: '{{ count }} addenda',
      title: 'Title',
      kind: 'Kind',
      description: 'Description',
    },
    attachment: {
      attachment_one: '{{ count }} attachment',
      attachment_other: '{{ count }} attachments',
    },
    comment: {
      supervisorComment: 'Comment',
    },
    cost: {
      costLine_one: '{{ count }} cost line',
      costLine_other: '{{ count }} cost lines',
      overline: 'Costs',
      billingCode: 'Name of billing code',
      billable: 'Billable',
      crane: 'Crane',
      workForceType: 'Labor',
      labor: 'Labor',
      noLine: 'No Line',
      quantity: 'Quantity',
      qty: 'Qty.',
      rate: 'Rate',
      rateUpdateReason: 'Rate Change Reason',
    },
    client: {
      inTheCareOf: 'I/C',
      orderNumber: 'PO #',
      projectManager: 'Project Manager',
      requirement: 'Requirement',
    },
    equipment: {
      kind: 'Equipment Kind',
      type: 'Type',
      boomConfiguration: 'Boom Configuration',
      craneConfiguration: 'Crane Configuration',
      craneSelectionMode: 'Crane Selection Mode',
      lifts: 'Lifts',
      configurationList: 'Configurations',
      configuration: 'Configuration',
      otherInformation: 'Other Information',
      transport: {
        transportKind: 'Transport Type',
        segments: {
          kind: 'Segment Type',
          craneSpeed: '{{ crane }} Speed',
          craneDistance: '{{ crane }} Distance',
          primaryCraneSpeed: '$t(field.equipment.transport.segments.craneSpeed, {"crane": "P"})',
          primaryCraneDistance: '$t(field.equipment.transport.segments.craneDistance, {"crane": "P"})',
          additionalCraneSpeed: '$t(field.equipment.transport.segments.craneSpeed, {"crane": "A{{ craneIndex }}"})',
          additionalCraneDistance: '$t(field.equipment.transport.segments.craneDistance, {"crane": "A{{ craneIndex }}"})',
        },
        craneTransportRate: 'Primary',
        additionalCraneTransportRate: 'Additional {{ craneIndex }}',
        boomCounterweightTransport: 'Boom and Counterweight Transport',
        flatbedTransport: 'Flatbed Transport',
      },
    },
    project: {
      branch: {
        dispatchBranch: 'Dispatch Branch',
        overline: 'Branches',
        salesBranch: 'Sales Branch',
      },
      date: {
        arrival: 'Arrival Date',
        date: 'Date',
        departure: 'Departure Date',
        duration: 'Job Duration',
        durationPerDay: 'Duration per Day',
        isSecondServing: '2nd serving',
        includeWeekendsAndHolidays: 'Includes weekends and holidays',
        notes: 'Notes and additional information',
        forbidOvertime: 'No overtime',
        ready: 'Ready Date',
      },
      work: {
        isEstablishedSchedule: 'Established Schedule',
        natureOfWork: 'Nature of Work',
        natureOfWorkSubCategory: 'Category',
        overline: 'Work',
        workDescription: 'Work Description',
        workSchedule: 'Work Schedule',
      },
    },
    questions: {
      hasParticularSituation_one: '{{ count }} particular situation',
      hasParticularSituation_other: '{{ count }} particular situations',
      hasNoParticularSituation: 'No particular situation',
      worksiteVisited: 'Worksite visited',
      worksiteNotVisited: 'Worksite not visited',
    },
    costs: {
      goToDesktop: 'Continue on the web application to access the quote calculator',
    },
  },
  info: 'Info',
  kind: {
    accessoriesRental: 'Accessories Rental',
    bare: 'Bare Crane',
    bareWithOperators: 'Bare with Operators',
    laborRental: 'Labor Rental',
    liftingPlan: 'Lifting Plan',
    liftingTest: 'Lifting Test',
    liftingTestOperated: 'Lifting Test Operated',
    operatedHourly: 'Operated Crane (hourly)',
    operatedMonthly: 'Operated Crane (monthly)',
    rollingEquipment: 'Rolling Equipment',
    storage: 'Storage',
  } satisfies Record<QuoteKind, string>,
  kindShort: {
    accessoriesRental: 'Accessories Rental',
    bare: 'Bare',
    bareWithOperators: 'Bare with Operators',
    laborRental: 'Labor Rental',
    liftingPlan: 'Lifting Plan',
    liftingTest: 'Lifting Test',
    liftingTestOperated: 'Lifting Test Operated',
    operatedHourly: 'Operated (hourly)',
    operatedMonthly: 'Operated (monthly)',
    rollingEquipment: 'Rolling Equipment',
    storage: 'Storage',
  } satisfies Record<QuoteKind, string>,
  list: {
    column: {
      friendlyId: 'ID',
      client: 'Client',
      worksite: 'Worksite',
      kind: 'Type',
      crane: 'Crane',
      status: 'Status',
      date: 'Date',
    },
  },
  pages: {
    accessories: {
      title: 'Accessories',
    },
    addenda: {
      title: 'Addenda',
    },
    addendaAndAttachments: {
      title: 'Addenda and Attachments',
    },
    comment: {
      title: 'Comment',
    },
    costLine: {
      title: 'Cost Lines',
    },
    client: {
      title: 'Client Information',
      requirementDescriptionsTitle: 'Client requirements',
      subsection: {
        management: 'Management',
      },
    },
    details: {
      copyTitle: 'Copy Quote',
      copyTitleShort: 'Copy Quote',
      shortTitle: 'Quote',
      title: 'Quote',
    },
    list: {
      tabs: {
        myClients: 'My Clients',
        myJobs: 'My Quotes',
        myJobsShort: 'My Quotes',
        myRegion: 'My Region',
      },
    },
    project: {
      title: 'Project Information',
      subsection: {
        branches: 'Branches',
        dateAndTime: 'Dates and time',
        work: 'Work',
        worksite: 'Worksite',
      },
    },
    questions: {
      title: 'Questions',
    },
    equipment: {
      title: 'Equipment Information',
      subsection: {
        craneSelector: 'Crane Selector',
      },
    },
    costs: {
      title: 'Costs Information',
      subsection: {
        additionalCranes: 'Additional Cranes',
        mainCrane: 'Main Crane',
        emptyCraneConfiguration: 'No configuration selected',
      },
    },
  },
  placeholder: {
    accessoryInfo: 'Select accessories (optional)',
    accessoryOutOfInventory: 'Write accessory',
    addAddenda: 'Add an addendum',
    addendaInfo: 'Enter addenda information (optional)',
    clientInfo: 'Enter client information',
    equipmentInfo: 'Enter equipment information',
    projectInfo: 'Enter project information',
    questionsInfo: 'Answer basic questions',
    costsInfo: 'Enter costs information',
    selectConfiguration: 'Select a configuration',
  },
  section: {
    addenda: 'Addenda',
    addendaAndAttachments: 'Addendas and attachments',
    calculator: {
      action: {
        calculate: 'Calculate',
        refresh: 'Recalculate',
        apply: 'Apply',
      },
      additionalFees: {
        header: 'Additional Fees',
      },
      adjustments: {
        header: 'Adjustments',
        segmentKind: {
          departure: 'Departure',
          return: 'Return',
          all: 'All',
        } satisfies Record<AdjustmentSegmentKind, string>,
        unit: {
          amount: 'Amount',
          percentage: 'Percentage',
        } satisfies Record<AdjustmentUnit, string>,
      },
      assemblyDisassembly: {
        header: 'Assembly and Disassembly',
        kind: {
          departure: '{{ crane }} - Assembly {{ kind }}',
          return: '{{ crane }} - Disassembly {{ kind }}',
        } satisfies Record<TransportSegmentKind, string>,
      },
      departureTotal: 'Departure amount',
      description: 'Description',
      descriptionPlaceholder: 'Write description',
      distance: 'Distance',
      flatbedBoomCounterweightTransport: {
        header: 'Flatbed and Boom & Counterweight Transport',
        kind: {
          flatbed: '{{ crane }} - {{ description }} - {{ transportSegmentKind }}',
          boomAndCounterweightFirstTrip: '{{ crane }} - {{ description }} - V1 - {{ transportSegmentKind }}',
          boomAndCounterweightOtherTrips: '{{ crane }} - {{ description }} - V2+ - {{ transportSegmentKind }}',
        } satisfies Record<CalculatorFlatbedBoomCounterweightTransportEntryKind, string>,
      },
      header: 'Calculator',
      hours: 'Hours',
      loadingUnloading: {
        header: 'Loading and Unloading',
        kind: {
          loading: 'Loading',
          unloading: 'Unloading',
        } satisfies Record<LoadingUnloading, string>,
      },
      minimumPrice: 'Min. Charge',
      mobileCraneTransport: {
        header: 'Mobile Crane Transport',
        kind: {
          mainCrane: {
            departure: 'P - Departure',
            return: 'P - Return',
          } satisfies Record<TransportSegmentKind, string>,
          additionalCrane: {
            departure: 'A{{ index }} - Departure',
            return: 'A{{ index }} - Return',
          } satisfies Record<TransportSegmentKind, string>,
        },
      },
      otherTransport: {
        header: 'Other Transport',
      },
      placeholder: 'Click the calculate button to activate the quote calculator',
      pleaseRecalculate: 'Attention, recalculation is required',
      quantity: 'Quantity',
      rate: 'Rate',
      returnTotal: 'Return amount',
      segmentKind: 'Segment type',
      speed: 'Speed',
      subTotal: 'Subtotal',
      supplementalEquipmentAndWorkForce: {
        header: 'Supplemental Equipment and Workforce',
      },
      surcharge: 'Surcharge',
      total: 'Total',
      travellingExpensesFee: 'Fee Km',
      type: 'Type',
      unit: 'Unit',
      value: 'Value',
    },
    client: 'Client',
    comment: 'Comment',
    costLines: 'Cost lines',
    project: 'Project',
    questions: 'Questions',
    equipment: 'Equipment',
    accessories: 'Accessories',
    costs: 'Costs',
    transport: {
      header: 'Transport',
      segments: 'Segments',
      craneTransportRate: 'Crane Rate',
      transportRate: 'Transport Rate',
    },
  },
  quotes: 'Quotes',
  status: {
    accepted: 'Accepted',
    canceled: 'Canceled',
    inWriting: 'In Writing',
    declined: 'Declined',
    revising: 'Revising',
    inApproval: 'In Approval',
    awaitingClient: 'Awaiting Client',
    locked: 'Locked',
  } satisfies Record<QuoteStatus, string>,
  transportKind: {
    crane: 'Fixed price crane transport',
    jibAndCounterweight: 'Jib and counterweight transport',
    mobilisationAndDemobilisation: 'Mobilisation and demobilisation',
  } satisfies Record<TransportKind, string>,
  transportSegmentKind: {
    departure: 'Departure',
    return: 'Return',
  } satisfies Record<TransportSegmentKind, string>,
};
