import {
  createFieldKey,
  undefinedIfEmpty,
  useFieldIsDirty,
  useFieldMapper,
  useFieldSetter,
  useFieldValue,
  useFormEtag,
  useFormIsDirty,
  useFormMappings,
} from '../common/utils/forms';
import { DataID, useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {
  arrPatchBy,
  flagRemoved,
  hasChanged,
  Patchable,
  PatchableEditProps,
  toPatchOperation,
  usePatchable,
} from '../common/utils/patchable';
import { ReactNode, useCallback, useEffect } from 'react';
import { useRenderItemById, useRenderSubFormCollection } from '../common/utils/patchableForm';
import { jobStageBaseFormContext } from './JobStageBaseFields';
import {
  additionalCranesSubFormContext,
  FieldAdditionalCranesBoomConfiguration,
  FieldAdditionalCranesCapacity,
  FieldAdditionalCranesConfigurationKind,
  FieldAdditionalCranesEquipmentKind,
  useFieldAdditionalCranesActive,
  useFieldAdditionalCranesBoomConfiguration,
  useFieldAdditionalCranesCapacity,
  useFieldAdditionalCranesConfigurationKind,
  useFieldAdditionalCranesEquipmentKind,
  useFieldAdditionalCranesId,
} from './AdditionalCranesSubFormFields';
import { useEffectEvent } from '../common/utils/effectUtils';
import { DateTime } from 'luxon';
import { parseDateTime, serializeDateTime } from '../common/utils/dateTimeUtils';
import { nanoid } from 'nanoid';
import { AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment$key } from './__generated__/AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment.graphql';
import { AdditionalCranesFields_AdditionalCranesManualCollectionFragment$key } from './__generated__/AdditionalCranesFields_AdditionalCranesManualCollectionFragment.graphql';
import { PatchOperationOfSaveAdditionalConfigurationInfoInput } from '../quote/__generated__/QuoteSaveButtonMutation.graphql';

export type AdditionalCrane = Patchable<{
  id: DataID;
  // Editable fields
  deletedAt: DateTime<true> | null;
  capacity: FieldAdditionalCranesCapacity;
  equipmentKind: FieldAdditionalCranesEquipmentKind;
  configurationKind: FieldAdditionalCranesConfigurationKind;
  boomConfiguration: FieldAdditionalCranesBoomConfiguration;
}>;

export function keySelector(v: AdditionalCrane): string {
  return v.id;
}

const fieldAdditionalCranesManualCollectionKey = createFieldKey<AdditionalCrane[]>();
export function useFieldAdditionalCranesManualCollection(
  $key: AdditionalCranesFields_AdditionalCranesManualCollectionFragment$key | null | undefined,
) {
  const { additionalCranesManual, ...rest } = useFieldAdditionalCranesManualCollectionRead($key);
  const setAdditionalCranesManual = useFieldSetter(jobStageBaseFormContext, fieldAdditionalCranesManualCollectionKey);
  const {
    append,
    replace: replaceAdditionalCraneManual,
    patch: patchAdditionalCraneManual,
  } = usePatchable(setAdditionalCranesManual, keySelector);

  const appendAdditionalCraneManual = useCallback((value: AdditionalCrane) => append({ ...value, id: nanoid() }), [append]);
  const clearAdditionalCranesManual = useCallback(
    () =>
      setAdditionalCranesManual(
        arrPatchBy(
          (v) => flagRemoved(v),
          () => true,
        ),
      ),
    [setAdditionalCranesManual],
  );

  const renderAdditionalCranesManualCollection = useRenderSubFormCollection(
    additionalCranesManual,
    setAdditionalCranesManual,
    keySelector,
    additionalCranesSubFormContext,
  );

  const renderAdditionalCranesManualItemById = useRenderItemById(
    additionalCranesManual,
    setAdditionalCranesManual,
    keySelector,
    additionalCranesSubFormContext,
  );

  return {
    additionalCranesManual,
    setAdditionalCranesManual,
    appendAdditionalCraneManual,
    replaceAdditionalCraneManual,
    patchAdditionalCraneManual,
    clearAdditionalCranesManual,
    renderAdditionalCranesManualCollection,
    renderAdditionalCranesManualItemById,
    ...rest,
  };
}

export function useFieldAdditionalCranesManualCollectionRead(
  $key: AdditionalCranesFields_AdditionalCranesManualCollectionFragment$key | null | undefined,
) {
  const $data = useFragment(
    graphql`
      fragment AdditionalCranesFields_AdditionalCranesManualCollectionFragment on ManualConfigurationInternal {
        additionalConfigurations {
          id
          capacity {
            capacity
            label
          }
          equipmentKind {
            id
            code
            label
          }
          configurationKind {
            id
            code
            label
          }
          boomConfiguration {
            id
            label
            preparationHours
          }
          boomConfiguration {
            vehicleIds {
              key
              make
              model
            }
          }
          deletedAt
        }
      }
    `,
    $key,
  );

  const additionalCranesManual = useFieldValue(
    jobStageBaseFormContext,
    fieldAdditionalCranesManualCollectionKey,
    () =>
      $data?.additionalConfigurations.map(({ deletedAt, capacity, equipmentKind, configurationKind, boomConfiguration, ...rest }) => ({
        deletedAt: parseDateTime(deletedAt),
        capacity: capacity ?? null,
        equipmentKind: equipmentKind ?? null,
        configurationKind: configurationKind ?? null,
        boomConfiguration: boomConfiguration ?? null,
        ...rest,
      })) ?? [],
  );

  const additionalCraneManualById = useCallback((id: DataID) => additionalCranesManual.find((a) => a.id === id), [additionalCranesManual]);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldAdditionalCranesManualCollectionKey);
  useMapper(
    (rows) => ({
      equipmentBase: {
        craneSelector: {
          manualConfiguration: {
            additionalConfigurations: additionalCraneToPatchable(rows),
          },
        },
      },
    }),
    [],
    'save',
  );
  const additionalCranesManualAreDirty = useFieldIsDirty(jobStageBaseFormContext, fieldAdditionalCranesManualCollectionKey);

  return {
    additionalCranesManual,
    additionalCranesManualAreDirty,
    additionalCraneManualById,
  };
}

const fieldAdditionalCranesAutomaticCollectionKey = createFieldKey<AdditionalCrane[]>();
export function useFieldAdditionalCranesAutomaticCollection(
  $key: AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment$key | null | undefined,
) {
  const { additionalCranesAutomatic, ...rest } = useFieldAdditionalCranesAutomaticCollectionRead($key);

  const setAdditionalCranesAutomatic = useFieldSetter(jobStageBaseFormContext, fieldAdditionalCranesAutomaticCollectionKey);

  const {
    append,
    replace: replaceAdditionalCraneAutomatic,
    patch: patchAdditionalCraneAutomatic,
  } = usePatchable(setAdditionalCranesAutomatic, keySelector);
  const appendAdditionalCraneAutomatic = useCallback((value: AdditionalCrane) => append({ ...value, id: nanoid() }), [append]);
  const clearAdditionalCranesAutomatic = useCallback(
    () =>
      setAdditionalCranesAutomatic(
        arrPatchBy(
          (v) => flagRemoved(v),
          () => true,
        ),
      ),
    [setAdditionalCranesAutomatic],
  );

  const renderAdditionalCranesAutomaticCollection = useRenderSubFormCollection(
    additionalCranesAutomatic,
    setAdditionalCranesAutomatic,
    keySelector,
    additionalCranesSubFormContext,
  );

  const renderAdditionalCranesAutomaticItemById = useRenderItemById(
    additionalCranesAutomatic,
    setAdditionalCranesAutomatic,
    keySelector,
    additionalCranesSubFormContext,
  );

  return {
    additionalCranesAutomatic,
    setAdditionalCranesAutomatic,
    appendAdditionalCraneAutomatic,
    replaceAdditionalCraneAutomatic,
    patchAdditionalCraneAutomatic,
    clearAdditionalCranesAutomatic,
    renderAdditionalCranesAutomaticCollection,
    renderAdditionalCranesAutomaticItemById,
    ...rest,
  };
}

export function useFieldAdditionalCranesAutomaticCollectionRead(
  $key: AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment$key | null | undefined,
) {
  const $data = useFragment(
    graphql`
      fragment AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment on AutomaticConfigurationInternal {
        additionalConfigurations {
          id
          capacity {
            capacity
            label
          }
          equipmentKind {
            id
            code
            label
          }
          configurationKind {
            id
            code
            label
          }
          boomConfiguration {
            id
            label
            preparationHours
          }
          deletedAt
        }
      }
    `,
    $key,
  );

  const additionalCranesAutomatic = useFieldValue(
    jobStageBaseFormContext,
    fieldAdditionalCranesAutomaticCollectionKey,
    () =>
      $data?.additionalConfigurations.map(({ deletedAt, capacity, equipmentKind, configurationKind, boomConfiguration, ...rest }) => ({
        deletedAt: parseDateTime(deletedAt),
        capacity: capacity ?? null,
        equipmentKind: equipmentKind ?? null,
        configurationKind: configurationKind ?? null,
        boomConfiguration: boomConfiguration ?? null,
        ...rest,
      })) ?? [],
  );

  const additionalCraneAutomaticById = useCallback(
    (id: DataID) => additionalCranesAutomatic.find((a) => a.id === id),
    [additionalCranesAutomatic],
  );

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldAdditionalCranesAutomaticCollectionKey);
  useMapper(
    (rows) => ({
      equipmentBase: {
        craneSelector: {
          automaticConfiguration: {
            additionalConfigurations: additionalCraneToPatchable(rows),
          },
        },
      },
    }),
    [],
    'save',
  );

  const additionalCranesAutomaticAreDirty = useFieldIsDirty(jobStageBaseFormContext, fieldAdditionalCranesAutomaticCollectionKey);

  return {
    additionalCranesAutomatic,
    additionalCranesAutomaticAreDirty,
    additionalCraneAutomaticById,
  };
}

export type AdditionalCranesFields_Item_PresentFn = AdditionalCranesFields_ItemContent_PresentFn;
export function AdditionalCranesFields_Item({
  index,
  disabled,
  presentFn,
  value,
  onChange: handleChange,
  ...patchableProps
}: {
  index: number;
  id: string;
  presentFn: AdditionalCranesFields_Item_PresentFn;
  disabled: boolean;
} & PatchableEditProps<AdditionalCrane>): ReactNode {
  useFieldAdditionalCranesId(value?.id ?? 'new');

  const { mapAll } = useFormMappings(additionalCranesSubFormContext);
  const sync = useEffectEvent((formState: unknown) => {
    // Enforce a pseudo dependency on current form state since mapAll is a stable function.
    // This is usually provided as the form etag.
    if (!formState) {
      return;
    }

    // TODO: Should be done as part of the patching functions.
    //  Prevent accidental changes when the component is disabled.
    if (disabled) {
      return;
    }

    handleChange(mapAll('sync'));
  });

  const formEtag = useFormEtag(additionalCranesSubFormContext);
  const formDirty = useFormIsDirty(additionalCranesSubFormContext);
  useEffect(() => {
    if (!formDirty) {
      return;
    }

    sync(formEtag);
  }, [formDirty, formEtag, sync]);

  return (
    <AdditionalCranesFields_ItemContent
      index={index}
      presentFn={presentFn}
      disabled={disabled}
      value={value}
      onChange={handleChange}
      {...patchableProps}
    />
  );
}

type AdditionalCranesFields_ItemContent_PresentFn = (
  id: string,
  index: number,
  render: {
    renderAdditionalCranesCapacity: () => ReactNode;
    renderAdditionalCranesEquipmentKind: () => ReactNode;
    renderAdditionalCranesConfigurationKind: () => ReactNode;
    renderAdditionalCranesBoomConfiguration: () => ReactNode;
    renderAdditionalCranesActive: () => ReactNode;
  },
) => ReactNode;
function AdditionalCranesFields_ItemContent({
  index,
  presentFn,
  disabled,
  value,
  id,
}: {
  index: number;
  presentFn: AdditionalCranesFields_ItemContent_PresentFn;
  disabled: boolean;
} & PatchableEditProps<AdditionalCrane>) {
  const { additionalCranesActive, renderAdditionalCranesActive } = useFieldAdditionalCranesActive(value.deletedAt, disabled);
  const removed = !additionalCranesActive;

  const { renderAdditionalCranesCapacity, additionalCranesCapacity } = useFieldAdditionalCranesCapacity(
    value.capacity,
    disabled || removed,
  );
  const { renderAdditionalCranesEquipmentKind, additionalCranesEquipmentKind } = useFieldAdditionalCranesEquipmentKind(
    value.equipmentKind,
    disabled || removed,
  );
  const { renderAdditionalCranesConfigurationKind, additionalCranesConfigurationKind } = useFieldAdditionalCranesConfigurationKind(
    value.configurationKind,
    disabled || removed,
  );
  const hasSelectedRequiredFields = !!additionalCranesCapacity && !!additionalCranesEquipmentKind && !!additionalCranesConfigurationKind;
  const { renderAdditionalCranesBoomConfiguration } = useFieldAdditionalCranesBoomConfiguration(
    value.boomConfiguration,
    disabled || removed || !hasSelectedRequiredFields,
  );

  useResetAdditionalCranesBoomConfiguration(value);

  return presentFn(id, index, {
    renderAdditionalCranesCapacity: () =>
      renderAdditionalCranesCapacity(additionalCranesConfigurationKind?.code || null, additionalCranesEquipmentKind?.code ?? null),
    renderAdditionalCranesEquipmentKind: () =>
      renderAdditionalCranesEquipmentKind(additionalCranesCapacity?.capacity ?? null, additionalCranesConfigurationKind?.code ?? null),
    renderAdditionalCranesConfigurationKind: () =>
      renderAdditionalCranesConfigurationKind(additionalCranesCapacity?.capacity ?? null, additionalCranesEquipmentKind?.code ?? null),
    renderAdditionalCranesBoomConfiguration: () =>
      renderAdditionalCranesBoomConfiguration(
        additionalCranesCapacity?.capacity ?? 0,
        additionalCranesConfigurationKind?.code ?? 0,
        additionalCranesEquipmentKind?.code ?? 0,
      ),
    renderAdditionalCranesActive: () => renderAdditionalCranesActive(),
  });
}

function useResetAdditionalCranesBoomConfiguration(value: AdditionalCrane) {
  const { additionalCranesCapacity, additionalCranesCapacityIsDirty } = useFieldAdditionalCranesCapacity(value?.capacity, true);
  const { additionalCranesEquipmentKind, additionalCranesEquipmentKindIsDirty } = useFieldAdditionalCranesEquipmentKind(
    value?.equipmentKind,
    true,
  );
  const { additionalCranesConfigurationKind, additionalCranesConfigurationKindIsDirty } = useFieldAdditionalCranesConfigurationKind(
    value?.configurationKind,
    true,
  );
  const { setAdditionalCranesBoomConfiguration } = useFieldAdditionalCranesBoomConfiguration(value?.boomConfiguration, false);

  useEffect(() => {
    if (!additionalCranesCapacityIsDirty && !additionalCranesEquipmentKindIsDirty && !additionalCranesConfigurationKindIsDirty) {
      return;
    }

    setAdditionalCranesBoomConfiguration(null);
  }, [
    additionalCranesCapacityIsDirty,
    additionalCranesEquipmentKindIsDirty,
    additionalCranesConfigurationKindIsDirty,
    additionalCranesCapacity,
    additionalCranesEquipmentKind,
    additionalCranesConfigurationKind,
    setAdditionalCranesBoomConfiguration,
  ]);
}

function additionalCraneToPatchable(
  additionalCranes: AdditionalCrane[],
): ReadonlyArray<PatchOperationOfSaveAdditionalConfigurationInfoInput> | null | undefined {
  return undefinedIfEmpty(
    additionalCranes
      .filter((v) => hasChanged(v))
      .map((v) =>
        toPatchOperation(v, keySelector, (val) => ({
          deletedAt: serializeDateTime(val.deletedAt),
          boomConfigurationId: val.boomConfiguration?.id ?? null,
          capacity: val.capacity?.capacity ?? null,
          equipmentKindCode: val.equipmentKind?.code ?? null,
          configurationKindCode: val.configurationKind?.code ?? null,
        })),
      ),
  );
}
