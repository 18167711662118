import { DataID, useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { createSharedStateKey, useSharedState } from '../common/utils/sharedState';
import { jobSharedStateContext } from './JobSharedState';
import {
  useFieldBoomLength,
  useFieldBoomLengthRead,
  useFieldCounterweight,
  useFieldCounterweightRead,
  useFieldCraneSelectorAutomaticFavoriteConfiguration,
  useFieldCraneSelectorModeRead,
  useFieldFavoriteConfigurationETag,
  useFieldJibLength,
  useFieldJibLengthRead,
  useFieldManualBoomConfiguration,
  useFieldManualBoomConfigurationRead,
  useFieldManualCapacityRead,
  useFieldManualConfigurationKindRead,
  useFieldManualEquipmentKindRead,
  useFieldManualVehicleIdsRead,
  useFieldMaxWeight,
  useFieldMaxWeightRead,
  useFieldOffsetAngle,
  useFieldOffsetAngleRead,
  useFieldRadius,
  useFieldRadiusRead,
} from './fields/CraneSelectorFields';
import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';

import { useCancellableSubscription } from '../common/hooks/useCancellableSubscription';
import { useOperations, useOperationsRelay } from '../AppSharedState';
import { Angle } from '../common/utils/dimensions/angle';
import { Length } from '../common/utils/dimensions/length';
import { Mass } from '../common/utils/dimensions/mass';
import {
  useFavoriteEquipment_useUpdateBoomConfigurationInfoFragment$data,
  useFavoriteEquipment_useUpdateBoomConfigurationInfoFragment$key,
} from './__generated__/useFavoriteEquipment_useUpdateBoomConfigurationInfoFragment.graphql';
import { useFavoriteEquipment_useUpdateBoomConfigurationInfo_initialValueFragment$key } from './__generated__/useFavoriteEquipment_useUpdateBoomConfigurationInfo_initialValueFragment.graphql';
import { _never } from '../common/utils/_never';
import { useFavoriteEquipmentFragment$key } from './__generated__/useFavoriteEquipmentFragment.graphql';
import { _throw } from '../common/utils/_throw';
import {
  useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromQueryResultFragment$data,
  useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromQueryResultFragment$key,
} from './__generated__/useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromQueryResultFragment.graphql';
import { useFieldAdditionalCranesAutomaticCollection, useFieldAdditionalCranesManualCollection } from './AdditionalCranesFields';
import { useFavoriteEquipment_useSynchronizeFavoriteEquipments_FromQueryPassthroughFragment$key } from './__generated__/useFavoriteEquipment_useSynchronizeFavoriteEquipments_FromQueryPassthroughFragment.graphql';
import { castJibKind, JibKind } from '../__enums__/JibKind';
import {
  useFavoriteEquipment_AdditionalsQuery,
  useFavoriteEquipment_AdditionalsQuery$data,
} from './__generated__/useFavoriteEquipment_AdditionalsQuery.graphql';
import {
  useFavoriteEquipment_PrimaryQuery,
  useFavoriteEquipment_PrimaryQuery$data,
} from './__generated__/useFavoriteEquipment_PrimaryQuery.graphql';
import { useFavoriteEquipment_useUpdateAutomaticAdditionalConfigurationFields_InitialValueFragment$key } from './__generated__/useFavoriteEquipment_useUpdateAutomaticAdditionalConfigurationFields_InitialValueFragment.graphql';
import {
  useFavoriteEquipment_useUpdateAutomaticAdditionalConfigurationFieldsFragment$data,
  useFavoriteEquipment_useUpdateAutomaticAdditionalConfigurationFieldsFragment$key,
} from './__generated__/useFavoriteEquipment_useUpdateAutomaticAdditionalConfigurationFieldsFragment.graphql';
import { sequenceEqual } from '../common/utils/arrayUtils';
import { useFavoriteEquipment_useResetBoomConfigurationFragment$key } from './__generated__/useFavoriteEquipment_useResetBoomConfigurationFragment.graphql';
import {
  useFavoriteEquipment_useUpdateManualBoomConfigurationFields_AdditionalBoomConfigurationFragment$data,
  useFavoriteEquipment_useUpdateManualBoomConfigurationFields_AdditionalBoomConfigurationFragment$key,
} from './__generated__/useFavoriteEquipment_useUpdateManualBoomConfigurationFields_AdditionalBoomConfigurationFragment.graphql';
import { useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromInitialValuesFragment$key } from './__generated__/useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromInitialValuesFragment.graphql';
import { useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromInitialValuesFragment$key } from './__generated__/useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromInitialValuesFragment.graphql';
import { CraneSelectorMode } from '../__enums__/CraneSelectorMode';
import {
  useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromQueryResultFragment$data,
  useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromQueryResultFragment$key,
} from './__generated__/useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromQueryResultFragment.graphql';
import { nanoid } from 'nanoid';
import { useFavoriteEquipment_FavoriteEquipmentProviderFragment$key } from './__generated__/useFavoriteEquipment_FavoriteEquipmentProviderFragment.graphql';
import { useFavoriteEquipment_SynchronizeManual_FromFieldValuesFragment$key } from './__generated__/useFavoriteEquipment_SynchronizeManual_FromFieldValuesFragment.graphql';
import { DateTime } from 'luxon';
import { useArrayLens } from '../common/hooks/useLens';
import { useEffectEvent } from '../common/utils/effectUtils';
import { useFavoriteEquipmentSelectedFragment$key } from './__generated__/useFavoriteEquipmentSelectedFragment.graphql';
import {
  useFavoriteEquipment_extraDataFragment$data,
  useFavoriteEquipment_extraDataFragment$key,
} from './__generated__/useFavoriteEquipment_extraDataFragment.graphql';
import { useFavoriteEquipment_SynchronizeAutomatic_FromFieldValuesFragment$key } from './__generated__/useFavoriteEquipment_SynchronizeAutomatic_FromFieldValuesFragment.graphql';
import { PluralKey, useFragmentPlural } from '../common/hooks/useFragmentPlural';
import { useConstantValue } from '../common/hooks/useConstantValue';

const FAVORITE_EQUIPMENT_PRIMARY_UPDATE_OPERATION_KEY = 'updatePrimaryEquipment';
const FAVORITE_EQUIPMENT_ADDITIONALS_UPDATE_OPERATION_KEY = 'updateAdditionalEquipments';

const useUpdateBoomConfigurationInfoKey = createSharedStateKey<
  useFavoriteEquipment_useUpdateBoomConfigurationInfoFragment$key | null | undefined
>(() => undefined);
const useUpdateManualAdditionalConfigurationFieldsKey = createSharedStateKey<
  useFavoriteEquipment_useUpdateManualBoomConfigurationFields_AdditionalBoomConfigurationFragment$key | null | undefined
>(() => undefined);
const useUpdateAutomaticAdditionalConfigurationFieldsKey = createSharedStateKey<
  useFavoriteEquipment_useUpdateAutomaticAdditionalConfigurationFieldsFragment$key | null | undefined
>(() => undefined);

const UNCHANGED = Symbol('UNCHANGED');
type UNCHANGED = typeof UNCHANGED;
const synchronizeFavoriteEquipmentsAutomaticKey = createSharedStateKey<{
  queryResult$keys:
    | ReadonlyArray<useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromQueryResultFragment$key[number] | null | UNCHANGED>
    | null
    | undefined;
  passthrough$key: useFavoriteEquipment_useSynchronizeFavoriteEquipments_FromQueryPassthroughFragment$key | null | undefined;
}>(() => ({ queryResult$keys: undefined, passthrough$key: undefined }));
const synchronizeFavoriteEquipmentsManualKey = createSharedStateKey<{
  queryResult$keys:
    | ReadonlyArray<useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromQueryResultFragment$key[number] | null | UNCHANGED>
    | null
    | undefined;
  passthrough$key: useFavoriteEquipment_useSynchronizeFavoriteEquipments_FromQueryPassthroughFragment$key | null | undefined;
}>(() => ({ queryResult$keys: undefined, passthrough$key: undefined }));

export type EquipmentNull = Readonly<{ key: string; boomConfigurationId: null }>;
export type EquipmentSelected = Readonly<{
  key: string;
  boomConfigurationId: DataID;
  active: boolean;
  boom: {
    boomLength: Length | null;
    boomExtensionLength: Length | null;
  };
  jib: {
    jibKind: JibKind;
    jibLength: Length | null;
    jibExtensionLength: Length | null;
    mainBoomAngleOffset: Angle | null;
    offsetAngle: Angle | null;
  } | null;
  boomConfigurationName: string | null;
  radius: Length | null;
  maxWeight: Mass | null;
  counterweight: Mass | null;
  vehicleIds: readonly Readonly<{ key: string; label: string | null | undefined }>[];
  usagePercentage: number | null;
  data: useFavoriteEquipment_extraDataFragment$data[number];
}>;
type Equipment = EquipmentNull | EquipmentSelected;
export type Equipments = Readonly<{ dirty: boolean; values: readonly Equipment[] }>;
const defaultEquipmentBoom = {
  boomLength: null,
  boomExtensionLength: null,
};
const defaultEquipmentJib = {
  jibKind: 'foldable' as JibKind,
  jibLength: null,
  offsetAngle: null,
  jibExtensionLength: null,
  mainBoomAngleOffset: null,
};
const defaultEquipmentInfo = {
  boomConfigurationName: null,
  radius: null,
  maxWeight: null,
  counterweight: null,
  vehicleIds: [],
  usagePercentage: null,
};

const equipmentNullFactory = () =>
  Object.freeze({
    key: nanoid(),
    boomConfigurationId: null,
  });

const FavoriteEquipment = createContext<{
  equipmentsAutomatic: Equipments;
  equipmentsManual: Equipments;
} | null>(null);

export function useFavoriteEquipment_Operations() {
  const {
    hasOperationInFlight,
    hasOperationInFlightForKey: hasOperationInFlightForIdentityResets,
    shouldNotify,
    shouldNotifyForKey: shouldNotifyForIdentityResets,
  } = useOperations(FAVORITE_EQUIPMENT_PRIMARY_UPDATE_OPERATION_KEY);
  const { hasOperationInFlightForKey: hasOperationInFlightForIdentityChanges, shouldNotifyForKey: shouldNotifyForIdentityChanges } =
    useOperations(FAVORITE_EQUIPMENT_ADDITIONALS_UPDATE_OPERATION_KEY);

  return {
    hasOperationInFlight,
    hasOperationInFlightForIdentityResets,
    hasOperationInFlightForIdentityChanges,
    shouldNotify,
    shouldNotifyForIdentityResets,
    shouldNotifyForIdentityChanges,
  };
}

/**
 * Return the selected equipment and the dirty state of the form
 * depending on the selected crane selector mode
 * @param $key
 */
export function useFavoriteEquipment_Selected($key: useFavoriteEquipmentSelectedFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment useFavoriteEquipmentSelectedFragment on CraneSelectorInternal {
        ...CraneSelectorFields_CraneSelectorModeFragment
      }
    `,
    $key,
  );
  const { craneSelectorMode, craneSelectorModeIsDirty } = useFieldCraneSelectorModeRead($data);
  const { equipmentsAutomatic, equipmentsManual } =
    useContext(FavoriteEquipment) ?? _throw('Trying to read FavoriteEquipment context outside of provider');

  const definedEquipmentsAutomatic = useMemo(() => equipmentsAutomatic.values.filter((i) => i != null), [equipmentsAutomatic.values]);
  const definedEquipmentsManual = useMemo(() => equipmentsManual.values.filter((i) => i != null), [equipmentsManual.values]);

  switch (craneSelectorMode) {
    case 'lifts':
      return {
        equipments: equipmentsAutomatic.values,
        equipmentsAreDirty: equipmentsAutomatic.dirty || craneSelectorModeIsDirty,
        definedIdentifiers: definedEquipmentsAutomatic,
      };
    case 'manual':
      return {
        equipments: equipmentsManual.values,
        equipmentsAreDirty: equipmentsManual.dirty || craneSelectorModeIsDirty,
        definedIdentifiers: definedEquipmentsManual,
      };
    default:
      return _never(craneSelectorMode);
  }
}

export function FavoriteEquipmentProvider({
  $key,
  manualEquipmentDisabled,
  equipmentRequired,
  children,
}: PropsWithChildren<{
  $key: useFavoriteEquipment_FavoriteEquipmentProviderFragment$key | null | undefined;
  manualEquipmentDisabled: boolean;
  equipmentRequired: boolean;
}>) {
  const $data = useFragment(
    graphql`
      fragment useFavoriteEquipment_FavoriteEquipmentProviderFragment on CraneSelectorInternal {
        ...useFavoriteEquipmentFragment
        automaticConfiguration {
          ...useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromInitialValuesFragment
        }
        manualConfiguration {
          ...useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromInitialValuesFragment
        }
      }
    `,
    $key,
  );

  const [equipmentsAutomatic, setEquipmentsAutomatic] = useSynchronizeFavoriteEquipmentAutomatic_FromInitialValues(
    $data?.automaticConfiguration,
  );
  const [equipmentsManual, setEquipmentsManual] = useSynchronizeFavoriteEquipmentManual_FromInitialValues($data?.manualConfiguration);

  useFavoriteEquipment(
    $data,
    manualEquipmentDisabled,
    equipmentRequired,
    equipmentsAutomatic,
    setEquipmentsAutomatic,
    equipmentsManual,
    setEquipmentsManual,
  );

  const value = useMemo(() => ({ equipmentsAutomatic, equipmentsManual }), [equipmentsAutomatic, equipmentsManual]);
  return <FavoriteEquipment.Provider value={value}>{children}</FavoriteEquipment.Provider>;
}

/**
 * Responsible for synchronizing the favorite equipment context with the user inputs in the form.
 * @param $key
 * @param manualEquipmentDisabled
 * @param equipmentRequired
 * @param equipmentsAutomatic
 * @param setEquipmentsAutomatic
 * @param equipmentsManual
 * @param setEquipmentsManual
 */
function useFavoriteEquipment(
  $key: useFavoriteEquipmentFragment$key | null | undefined,
  manualEquipmentDisabled: boolean,
  equipmentRequired: boolean,
  equipmentsAutomatic: Equipments,
  setEquipmentsAutomatic: Dispatch<SetStateAction<Equipments>>,
  equipmentsManual: Equipments,
  setEquipmentsManual: Dispatch<SetStateAction<Equipments>>,
) {
  const initialValues$data = useFragment(
    graphql`
      fragment useFavoriteEquipmentFragment on CraneSelectorInternal {
        ...useFavoriteEquipment_useUpdateBoomConfigurationInfo_initialValueFragment
        ...CraneSelectorFields_useFieldCraneSelectorAutomaticFavoriteConfigurationFragment
        ...useFavoriteEquipment_useUpdateAutomaticAdditionalConfigurationFields_InitialValueFragment
        ...useFavoriteEquipment_SynchronizeAutomatic_FromFieldValuesFragment
        automaticConfiguration {
          ...AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment
        }
        manualConfiguration {
          ...useFavoriteEquipment_SynchronizeManual_FromFieldValuesFragment
          ...AdditionalCranesFields_AdditionalCranesManualCollectionFragment
          userInput {
            ...useFavoriteEquipment_useResetBoomConfigurationFragment
            ...CraneSelectorFields_CraneCapacityFragment
            ...CraneSelectorFields_Manual_OffsetAngleFragment
            ...CraneSelectorFields_Manual_BoomConfigurationFragment
            ...CraneSelectorFields_EquipmentKindFragment
            ...CraneSelectorFields_CraneConfigurationKindFragment
          }
        }
      }
    `,
    $key,
  );

  const fetchQueryPrimary = useOperationsRelay(FAVORITE_EQUIPMENT_PRIMARY_UPDATE_OPERATION_KEY);
  const fetchQueryAdditionals = useOperationsRelay(FAVORITE_EQUIPMENT_ADDITIONALS_UPDATE_OPERATION_KEY);

  const { endOperation: endOperationForPrimary } = useOperations(FAVORITE_EQUIPMENT_PRIMARY_UPDATE_OPERATION_KEY);

  const [, setSubscriptionPrimary] = useCancellableSubscription();
  const [, setSubscriptionAdditionals] = useCancellableSubscription();
  const [, setUpdateBoomConfig$key] = useSharedState(jobSharedStateContext, useUpdateBoomConfigurationInfoKey);
  const [, setFavoriteEquipmentAutomatic$key] = useSharedState(jobSharedStateContext, synchronizeFavoriteEquipmentsAutomaticKey);
  const [, setFavoriteEquipmentManual$key] = useSharedState(jobSharedStateContext, synchronizeFavoriteEquipmentsManualKey);
  const [, setUpdateAdditionalAutomaticConfiguration$key] = useSharedState(
    jobSharedStateContext,
    useUpdateAutomaticAdditionalConfigurationFieldsKey,
  );
  const [, setUpdateAdditionalManualConfiguration$key] = useSharedState(
    jobSharedStateContext,
    useUpdateManualAdditionalConfigurationFieldsKey,
  );

  const { capacity, capacityIsDirty } = useFieldManualCapacityRead(initialValues$data?.manualConfiguration.userInput);
  const { equipmentKind, equipmentKindIsDirty } = useFieldManualEquipmentKindRead(initialValues$data?.manualConfiguration.userInput);
  const { configurationKind, configurationKindIsDirty } = useFieldManualConfigurationKindRead(
    initialValues$data?.manualConfiguration.userInput,
  );
  const { boomConfiguration, boomConfigurationIsDirty } = useFieldManualBoomConfigurationRead(
    initialValues$data?.manualConfiguration.userInput,
  );

  const { additionalCranesManual, additionalCranesManualAreDirty } = useFieldAdditionalCranesManualCollection(
    initialValues$data?.manualConfiguration,
  );
  const { additionalCranesAutomatic, additionalCranesAutomaticAreDirty } = useFieldAdditionalCranesAutomaticCollection(
    initialValues$data?.automaticConfiguration,
  );

  const { craneConfigurationCollectionFavorite, craneConfigurationCollectionFavoriteIsDirty } =
    useFieldCraneSelectorAutomaticFavoriteConfiguration(initialValues$data, equipmentRequired);

  const matchingConfigurationEquipment$data = useFragment(
    graphql`
      fragment useFavoriteEquipment_favoriteAutomaticEquipmentFragment on AutomaticConfigurationInfo {
        boomConfigurationId
        ...useFavoriteEquipment_useSynchronizeFavoriteEquipments_FromQueryPassthroughFragment
      }
    `,
    craneConfigurationCollectionFavorite?.matchingConfigurationEquipment$key,
  );

  // effect Event to update the favorite equipment with the data from the back-end, or clear the data if the field was cleared. (BoomConfigurationId changed)
  const updatePrimaryEquipment = useEffectEvent((boomConfigurationId: string | null, craneSelectorMode: CraneSelectorMode) => {
    const query = graphql`
      query useFavoriteEquipment_PrimaryQuery($id: ID!) {
        primaryEquipment: node(id: $id) @required(action: THROW) {
          ... on BoomConfigurationSnapshot {
            ...useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromQueryResultFragment
            ...useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromQueryResultFragment
            ...useFavoriteEquipment_useUpdateBoomConfigurationInfoFragment
            additionalBoomConfigurations {
              ...useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromQueryResultFragment
              ...useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromQueryResultFragment
              ...useFavoriteEquipment_useUpdateAutomaticAdditionalConfigurationFieldsFragment
              ...useFavoriteEquipment_useUpdateManualBoomConfigurationFields_AdditionalBoomConfigurationFragment
            }
          }
        }
      }
    `;

    // clear everything if there is no selected equipment
    if (!boomConfigurationId) {
      switch (craneSelectorMode) {
        case 'lifts':
          setUpdateAdditionalAutomaticConfiguration$key(null);
          return setFavoriteEquipmentAutomatic$key({ queryResult$keys: null, passthrough$key: null });

        case 'manual':
          setUpdateAdditionalManualConfiguration$key(null);
          return setFavoriteEquipmentManual$key({ queryResult$keys: null, passthrough$key: null });
        default:
          return _never(craneSelectorMode);
      }
    }

    // Prevents the race condition where both updatePrimary and updateAdditional queries are sent in parallel
    setSubscriptionAdditionals(undefined);

    setSubscriptionPrimary(
      fetchQueryPrimary<useFavoriteEquipment_PrimaryQuery>(
        query,
        {
          id: boomConfigurationId,
        },
        [
          (result) => {
            switch (craneSelectorMode) {
              case 'lifts':
                return setFavoriteEquipmentAutomatic$key({
                  queryResult$keys: [result.primaryEquipment, ...getPrimaryAdditionalEquipments(result)],
                  passthrough$key: matchingConfigurationEquipment$data ?? null,
                });
              case 'manual':
                return setFavoriteEquipmentManual$key({
                  queryResult$keys: [result.primaryEquipment, ...getPrimaryAdditionalEquipments(result)],
                  passthrough$key: null,
                });
              default:
                return _never(craneSelectorMode);
            }
          },
          (result) => {
            if (craneSelectorMode === 'manual') setUpdateBoomConfig$key(result.primaryEquipment);
            else endOperationForPrimary();
          },
          (result) => {
            switch (craneSelectorMode) {
              case 'lifts':
                return setUpdateAdditionalAutomaticConfiguration$key(getPrimaryAdditionalEquipments(result));
              case 'manual':
                return setUpdateAdditionalManualConfiguration$key(getPrimaryAdditionalEquipments(result));
              default:
                return _never(craneSelectorMode);
            }
          },
        ],
      ).subscribe({}),
    );
  });

  useEffect(() => {
    if (!configurationKindIsDirty && !capacityIsDirty && !equipmentKindIsDirty && !boomConfigurationIsDirty) {
      return;
    }
    return updatePrimaryEquipment((configurationKind && capacity && equipmentKind && boomConfiguration?.id) ?? null, 'manual');
  }, [
    boomConfiguration?.id,
    boomConfigurationIsDirty,
    capacity,
    capacityIsDirty,
    configurationKind,
    configurationKindIsDirty,
    equipmentKind,
    equipmentKindIsDirty,
    updatePrimaryEquipment,
  ]);

  useEffect(() => {
    if (!craneConfigurationCollectionFavoriteIsDirty) {
      return;
    }

    return updatePrimaryEquipment(matchingConfigurationEquipment$data?.boomConfigurationId ?? null, 'lifts');
  }, [craneConfigurationCollectionFavoriteIsDirty, matchingConfigurationEquipment$data?.boomConfigurationId, updatePrimaryEquipment]);

  // effect Event to update the additional equipment when an additional Boom Configuration Id is changed
  const updateAdditionalEquipments = useEffectEvent(
    (additionalBoomConfigurationIds: (string | null)[], craneSelectorMode: CraneSelectorMode) => {
      const previousAdditionalCranes = (() => {
        switch (craneSelectorMode) {
          case 'lifts': {
            const [, ...additionalsAutomatic] = equipmentsAutomatic.values;
            return additionalsAutomatic;
          }
          case 'manual': {
            const [, ...additionalsManual] = equipmentsManual.values;
            return additionalsManual;
          }
          default:
            return _never(craneSelectorMode);
        }
      })();

      // if the list of boomConfigurationId is the same, we don't need to update the additional equipment
      if (
        sequenceEqual(
          previousAdditionalCranes.map((a) => a.boomConfigurationId),
          additionalBoomConfigurationIds,
        )
      ) {
        return;
      }

      const query = graphql`
        query useFavoriteEquipment_AdditionalsQuery($ids: [ID!]!) {
          additionalEquipments: nodes(ids: $ids) {
            ... on BoomConfigurationSnapshot {
              id
              ...useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromQueryResultFragment
              ...useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromQueryResultFragment
            }
          }
        }
      `;

      setSubscriptionAdditionals(
        fetchQueryAdditionals<useFavoriteEquipment_AdditionalsQuery>(
          query,
          {
            ids: additionalBoomConfigurationIds.filter((id) => id != null),
          },
          [
            (result) => {
              switch (craneSelectorMode) {
                case 'lifts':
                  return setFavoriteEquipmentAutomatic$key({
                    queryResult$keys: [UNCHANGED, ...getAdditionalEquipments(additionalBoomConfigurationIds, result)],
                    passthrough$key: matchingConfigurationEquipment$data ?? null,
                  });
                case 'manual':
                  return setFavoriteEquipmentManual$key({
                    queryResult$keys: [UNCHANGED, ...getAdditionalEquipments(additionalBoomConfigurationIds, result)],
                    passthrough$key: null,
                  });
                default:
                  return _never(craneSelectorMode);
              }
            },
          ],
        ).subscribe({}),
      );
    },
  );

  const additionalBoomConfigurationAutomatic = useArrayLens(
    additionalCranesAutomatic.filter((ac) => !ac.$removed),
    'boomConfiguration',
  );

  useEffect(() => {
    if (!additionalCranesAutomaticAreDirty) {
      return;
    }
    updateAdditionalEquipments(
      additionalBoomConfigurationAutomatic.map((a) => a.boomConfiguration?.id ?? null),
      'lifts',
    );
  }, [additionalBoomConfigurationAutomatic, additionalCranesAutomaticAreDirty, updateAdditionalEquipments]);

  const additionalBoomConfigurationManual = useArrayLens(
    additionalCranesManual.filter((ac) => !ac.$removed),
    'boomConfiguration',
  );

  useEffect(() => {
    if (!additionalCranesManualAreDirty) {
      return;
    }
    updateAdditionalEquipments(
      additionalBoomConfigurationManual.map((c) => c.boomConfiguration?.id ?? null),
      'manual',
    );
  }, [additionalCranesManualAreDirty, additionalBoomConfigurationManual, updateAdditionalEquipments]);

  useResetBoomConfiguration(initialValues$data?.manualConfiguration?.userInput, manualEquipmentDisabled, equipmentRequired);
  useSynchronizeFavoriteEquipmentAutomatic_FromQuery(setEquipmentsAutomatic);
  useSynchronizeFavoriteEquipmentManual_FromQuery(setEquipmentsManual);
  useFavoriteEquipment_SynchronizeAutomatic_FromFieldValues(initialValues$data, setEquipmentsAutomatic);
  useFavoriteEquipment_SynchronizeManual_FromFieldValues(initialValues$data?.manualConfiguration, setEquipmentsManual);
  useUpdateManualBoomConfigurationFields(initialValues$data, manualEquipmentDisabled, equipmentRequired);
  useUpdateAutomaticAdditionalConfigurationFields(initialValues$data);
}

/**
 * Return the list of additional boom configuration that are part of the primary equipment
 * Throw an exception if the query result contains a null boom configuration
 * @param result
 */
function getPrimaryAdditionalEquipments(result: useFavoriteEquipment_PrimaryQuery$data) {
  const additionalEquipments = result.primaryEquipment?.additionalBoomConfigurations?.filter((a) => a != null);
  if (additionalEquipments == null || additionalEquipments.length !== result.primaryEquipment.additionalBoomConfigurations?.length) {
    throw new Error(
      `Server did not reply with valid additional boom configuration for equipment. Got ${additionalEquipments?.length ?? 0}, expected ${result.primaryEquipment.additionalBoomConfigurations?.length ?? 0}.`,
    );
  }

  return additionalEquipments;
}

/**
 * Return the list of additional boom configuration that were returned from the additional configuration query
 * If a boom configuration is not found, it will be replaced by null
 * Throw an exception if the query result contains a null boom configuration
 * @param sourceIds
 * @param result
 */
function getAdditionalEquipments(sourceIds: (string | null)[], result: useFavoriteEquipment_AdditionalsQuery$data) {
  const additionalEquipments = result.additionalEquipments?.filter((a) => a != null);
  if (additionalEquipments == null || additionalEquipments.length !== result.additionalEquipments?.length) {
    throw new Error(
      `Server did not reply with valid additional boom configuration for equipment. Got ${additionalEquipments?.length ?? 0}, expected ${result.additionalEquipments?.length ?? 0}.`,
    );
  }

  return sourceIds.map((id) => additionalEquipments.find((a) => a.id === id) ?? null);
}

/**
 * Return an array of $data that can be used by outside hooks to get some extra data
 * that are not exposed by the SelectedEquipment type
 * @param data$key
 */
function useStaticData(data$key: PluralKey<useFavoriteEquipment_extraDataFragment$key> | null | undefined) {
  return useFragmentPlural(
    graphql`
      fragment useFavoriteEquipment_extraDataFragment on BoomConfigurationSnapshot @relay(plural: true) {
        id
        ...EquipmentFormSectionCompact_FavoriteEquipmentDataFragment
        ...ServiceCallSaveButton_useButtonColorDependingOnFormState_FavoriteEquipmentDataFragment
        ...SaleDateFields_FavoriteEquipmentDataFragment
        ...SalesDetails_useBillingCodeSearchInput_FavoriteEquipmentDataFragment
        ...useSuggestions_FavoriteEquipmentDataFragment
        ...CraneConfiguration_FavoritePrimaryEquipment_CardDataFragment
        ...CraneConfiguration_ConfigurationListItemSummary_FavoriteEquipmentFragment
        ...useAutomaticAddenda_favoriteEquipmentDataFragment
        ...QuoteEquipmentFields_useTransportSegments_CraneInfos_DefaultDataFragment
      }
    `,
    data$key,
  );
}

/**
 * responsible for updating the favorite equipment context
 * when the user changes the selected automatic equipment
 *
 * @param setFavoriteAutomatic
 */
function useSynchronizeFavoriteEquipmentAutomatic_FromQuery(setFavoriteAutomatic: Dispatch<SetStateAction<Equipments>>) {
  const { endOperation: endOperationForPrimary } = useOperations(FAVORITE_EQUIPMENT_PRIMARY_UPDATE_OPERATION_KEY);
  const { endOperation: endOperationForAdditionals } = useOperations(FAVORITE_EQUIPMENT_ADDITIONALS_UPDATE_OPERATION_KEY);
  const [keys] = useSharedState(jobSharedStateContext, synchronizeFavoriteEquipmentsAutomaticKey);

  // remove the null and UNCHANGED keys
  const cleanQueryResult$key: useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromQueryResultFragment$key | undefined =
    keys.queryResult$keys?.filter((k) => k != null && k !== UNCHANGED);

  const equipmentsFromQuery$data = useFragment(
    graphql`
      fragment useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromQueryResultFragment on BoomConfigurationSnapshot
      @relay(plural: true) {
        ...useFavoriteEquipment_extraDataFragment
        id
        deletedAt
        boomLength
        jibLength
        counterweight
        vehicleIds {
          key
          label
        }
      }
    `,
    cleanQueryResult$key,
  );

  const passthrough$data = useFragment(
    graphql`
      fragment useFavoriteEquipment_useSynchronizeFavoriteEquipments_FromQueryPassthroughFragment on AutomaticConfigurationInfo {
        boomConfigurationName
        boomLength
        boomExtensionLength
        jibLength
        jibExtensionLength
        counterweight
        vehicleId {
          key
          label
        }
        displayUsagePercentage
        maxWeight
        radius
        jibKind
        jib {
          offsetAngle
          ... on JibVariable {
            mainBoomAngleOffset
          }
          ... on JibVariableWithExtension {
            mainBoomAngleOffset
          }
        }
      }
    `,
    keys.passthrough$key,
  );

  const data$data = useStaticData(equipmentsFromQuery$data);

  const updateFromQuery = useEffectEvent(
    (deps: useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromQueryResultFragment$data | null) => {
      endOperationForPrimary();
      endOperationForAdditionals();
      if (!deps) {
        setFavoriteAutomatic({ dirty: true, values: [equipmentNullFactory()] });
      } else {
        setFavoriteAutomatic((prevState) => {
          return {
            dirty: true,
            values:
              keys.queryResult$keys?.map((k, i) => {
                switch (k) {
                  case undefined:
                  case null:
                    return equipmentNullFactory();
                  case UNCHANGED:
                    return (
                      prevState.values[i] ??
                      _throw(new Error(`Unexpected UNCHANGED at index ${i} Out of range of ${prevState.values.length}.`))
                    );
                  default: {
                    const fragmentKeyIndex = cleanQueryResult$key?.findIndex((ck) => ck === k) ?? -1;
                    const dep =
                      deps[fragmentKeyIndex] ?? _throw(new Error(`Value at index ${fragmentKeyIndex} out of range of ${deps.length}.`));
                    const data =
                      data$data?.[fragmentKeyIndex] ??
                      _throw(new Error(`Data at index ${fragmentKeyIndex} out of range of ${data$data?.length}.`));

                    return {
                      key: nanoid(),
                      boomConfigurationId: dep.id,
                      boomConfigurationName: passthrough$data?.boomConfigurationName ?? null,
                      active: i === 0 ? true : !dep.deletedAt,
                      boom: {
                        boomLength: Length.parse(dep.boomLength),
                        boomExtensionLength: Length.parse(passthrough$data?.boomExtensionLength),
                      },
                      jib: passthrough$data?.jibKind
                        ? {
                            jibKind: castJibKind(passthrough$data?.jibKind),
                            jibLength: Length.parse(dep.jibLength),
                            offsetAngle: Angle.parse(passthrough$data?.jib?.offsetAngle),
                            jibExtensionLength: Length.parse(passthrough$data?.jibExtensionLength),
                            mainBoomAngleOffset: Angle.parse(passthrough$data?.jib?.mainBoomAngleOffset),
                          }
                        : null,
                      radius: Length.parse(passthrough$data?.radius),
                      maxWeight: Mass.parse(passthrough$data?.maxWeight),
                      counterweight: Mass.parse(dep.counterweight),
                      vehicleIds: dep.vehicleIds,
                      usagePercentage: passthrough$data?.displayUsagePercentage ?? null,
                      data: data,
                    };
                  }
                }
              }) ?? _throw(new Error('Keys should always be set if deps are passed')),
          };
        });
      }
    },
  );

  useEffect(() => {
    if (keys.queryResult$keys === undefined) {
      return;
    }
    updateFromQuery(equipmentsFromQuery$data ?? null);
  }, [equipmentsFromQuery$data, keys, updateFromQuery]);
}

/**
 * responsible for updating the favorite equipment context
 * when the user changes the selected manual boom configuration id
 *
 * @param setFavoriteManual
 */
function useSynchronizeFavoriteEquipmentManual_FromQuery(setFavoriteManual: Dispatch<SetStateAction<Equipments>>) {
  const { endOperation: endOperationForPrimary } = useOperations(FAVORITE_EQUIPMENT_PRIMARY_UPDATE_OPERATION_KEY);
  const { endOperation: endOperationForAdditionals } = useOperations(FAVORITE_EQUIPMENT_ADDITIONALS_UPDATE_OPERATION_KEY);
  const [keys] = useSharedState(jobSharedStateContext, synchronizeFavoriteEquipmentsManualKey);
  const cleanQueryResult$key: useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromQueryResultFragment$key | undefined =
    keys.queryResult$keys?.filter((k) => k != null && k !== UNCHANGED);

  const equipmentsFromQuery$data = useFragment(
    graphql`
      fragment useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromQueryResultFragment on BoomConfigurationSnapshot
      @relay(plural: true) {
        ...useFavoriteEquipment_extraDataFragment
        id
        deletedAt
        boomLength
        jibLength
        counterweight
        vehicleIds {
          key
          label
        }
      }
    `,
    cleanQueryResult$key,
  );

  const data$data = useStaticData(equipmentsFromQuery$data);

  const updateFromQuery = useEffectEvent(
    (deps: useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromQueryResultFragment$data | null) => {
      endOperationForPrimary();
      endOperationForAdditionals();
      if (!deps) {
        setFavoriteManual({ dirty: true, values: [equipmentNullFactory()] });
      } else {
        setFavoriteManual((prevState) => {
          return {
            dirty: true,
            values:
              keys.queryResult$keys?.map((k, i) => {
                switch (k) {
                  case undefined:
                  case null:
                    return equipmentNullFactory();
                  case UNCHANGED:
                    return (
                      prevState.values[i] ??
                      _throw(new Error(`Unexpected UNCHANGED at index ${i} Out of range of ${prevState.values.length}.`))
                    );
                  default: {
                    const fragmentKeyIndex = cleanQueryResult$key?.findIndex((ck) => ck === k) ?? -1;
                    const dep =
                      deps[fragmentKeyIndex] ?? _throw(new Error(`Value at index ${fragmentKeyIndex} out of range of ${deps.length}.`));
                    const data =
                      data$data?.[fragmentKeyIndex] ??
                      _throw(new Error(`Data at index ${fragmentKeyIndex} out of range of ${data$data?.length}.`));

                    return {
                      key: nanoid(),
                      boomConfigurationId: dep.id,
                      active: i === 0 ? true : !dep.deletedAt,
                      boom: {
                        ...defaultEquipmentBoom,
                        boomLength: Length.parse(dep.boomLength),
                      },
                      jib: {
                        ...defaultEquipmentJib,
                        jibKind: 'foldable' as JibKind,
                        jibLength: Length.parse(dep.jibLength),
                      },
                      ...defaultEquipmentInfo,
                      counterweight: Mass.parse(dep.counterweight),
                      vehicleIds: dep.vehicleIds,
                      data: data,
                    };
                  }
                }
              }) ?? _throw(new Error('Keys should always be set if deps are passed')),
          };
        });
      }
    },
  );

  useEffect(() => {
    if (keys.queryResult$keys === undefined) {
      return;
    }
    updateFromQuery(equipmentsFromQuery$data ?? null);
  }, [equipmentsFromQuery$data, keys, updateFromQuery]);
}

/**
 * Return a state with the initial values of the automatic equipment
 *
 * @param initialValueAutomatic$key
 */
function useSynchronizeFavoriteEquipmentAutomatic_FromInitialValues(
  initialValueAutomatic$key:
    | useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromInitialValuesFragment$key
    | null
    | undefined,
) {
  const initialValuesAutomatic$data = useFragment(
    graphql`
      fragment useFavoriteEquipment_useSynchronizeFavoriteEquipmentsAutomatic_FromInitialValuesFragment on AutomaticConfigurationInternal {
        selectedMatchingConfiguration {
          isFavorite
          boomConfigurationName
          boomExtensionLength
          boomLength
          jibLength
          jibExtensionLength
          radius
          maxWeight
          counterweight
          vehicleId {
            key
            label
          }
          jibKind
          jib {
            offsetAngle
          }
          boomConfiguration @required(action: THROW) {
            id
            ...useFavoriteEquipment_extraDataFragment
          }
        }
        additionalConfigurations {
          deletedAt
          boomConfiguration {
            id
            boomLength
            jibLength
            counterweight
            vehicleIds {
              key
              label
            }
            ...useFavoriteEquipment_extraDataFragment
          }
        }
      }
    `,
    initialValueAutomatic$key,
  );

  const initialBoomConfigurationsAutomatic$data = useConstantValue(() => {
    const selectedConfiguration = initialValuesAutomatic$data?.selectedMatchingConfiguration;
    if (!selectedConfiguration) {
      return [];
    }
    const { boomConfiguration, ...rest } = selectedConfiguration;
    const primary = { type: 'primary', ...rest, ...boomConfiguration, deletedAt: null } as const;
    const additionals = initialValuesAutomatic$data.additionalConfigurations.map((a) =>
      a.boomConfiguration != null
        ? ({
            type: 'additional',
            ...a.boomConfiguration,
            deletedAt: a.deletedAt ?? null,
          } as const)
        : null,
    );
    return [primary, ...additionals];
  });

  const cleanInitialBoomConfigurationsAutomatic$data = initialBoomConfigurationsAutomatic$data.filter((d) => d != null);
  const staticData$data = useStaticData(cleanInitialBoomConfigurationsAutomatic$data);

  return useState<Equipments>(() => {
    if (!initialBoomConfigurationsAutomatic$data[0]) {
      return { dirty: false, values: [equipmentNullFactory()] };
    }
    return {
      dirty: false,
      values: [
        ...initialBoomConfigurationsAutomatic$data.map((equipment, index) => {
          return equipment == null
            ? equipmentNullFactory()
            : equipment.type === 'primary'
              ? {
                  key: nanoid(),
                  boomConfigurationId: equipment.id,
                  active: true,
                  boom: {
                    ...defaultEquipmentBoom,
                    boomLength: Length.parse(equipment.boomLength),
                    boomExtensionLength: Length.parse(equipment.boomExtensionLength),
                  },
                  jib: equipment.jibKind
                    ? {
                        ...defaultEquipmentJib,
                        jibKind: castJibKind(equipment.jibKind),
                        jibLength: Length.parse(equipment.jibLength),
                        jibExtensionLength: Length.parse(equipment.jibExtensionLength),
                        offsetAngle: Angle.parse(equipment.jib?.offsetAngle),
                      }
                    : null,
                  ...defaultEquipmentInfo,
                  boomConfigurationName: equipment.boomConfigurationName ?? null,
                  radius: Length.parse(equipment.radius),
                  maxWeight: Mass.parse(equipment.maxWeight),
                  counterweight: Mass.parse(equipment.counterweight),
                  vehicleIds: [equipment.vehicleId],
                  data: staticData$data?.[index] ?? _throw('Additional data length for favorite equipment mismatched.'),
                }
              : {
                  key: nanoid(),
                  boomConfigurationId: equipment.id,
                  active: !equipment.deletedAt,
                  boom: defaultEquipmentBoom,
                  jib: {
                    ...defaultEquipmentJib,
                    jibLength: Length.parse(equipment.jibLength),
                  },
                  ...defaultEquipmentInfo,
                  counterweight: Mass.parse(equipment.counterweight),
                  vehicleIds: equipment.vehicleIds,
                  data: staticData$data?.[index] ?? _throw('Additional data length for favorite equipment mismatched.'),
                };
        }),
      ],
    };
  });
}

/**
 * Return a state with the initial values of the manual equipment.
 * @param initialValueManual$key
 */
function useSynchronizeFavoriteEquipmentManual_FromInitialValues(
  initialValueManual$key: useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromInitialValuesFragment$key | null | undefined,
) {
  const initialValuesManual$data = useFragment(
    graphql`
      fragment useFavoriteEquipment_useSynchronizeFavoriteEquipmentManual_FromInitialValuesFragment on ManualConfigurationInternal {
        userInput {
          ...CraneSelectorFields_Manual_BoomLengthFragment
          ...CraneSelectorFields_Manual_JibLengthFragment
          ...CraneSelectorFields_Manual_OffsetAngleFragment
          ...CraneSelectorFields_Manual_RadiusFragment
          ...CraneSelectorFields_Manual_MaxWeightFragment
          ...CraneSelectorFields_Manual_CounterweightFragment
          ...CraneSelectorFields_VehicleIdsFragment
          boomConfiguration {
            id
            ...useFavoriteEquipment_extraDataFragment
          }
        }
        additionalConfigurations {
          deletedAt
          boomConfiguration {
            id
            boomLength
            jibLength
            counterweight
            vehicleIds {
              key
              label
            }
            ...useFavoriteEquipment_extraDataFragment
          }
        }
      }
    `,
    initialValueManual$key,
  );

  /**
   * doc why some field are needed but boomConfig and additions is not
   */
  const userInputKey = initialValuesManual$data?.userInput;
  const { offsetAngle } = useFieldOffsetAngleRead(userInputKey);
  const { boomLength } = useFieldBoomLengthRead(userInputKey);
  const { jibLength } = useFieldJibLengthRead(userInputKey);
  const { radius } = useFieldRadiusRead(userInputKey);
  const { maxWeight } = useFieldMaxWeightRead(userInputKey);
  const { counterweight } = useFieldCounterweightRead(userInputKey);
  const { vehicleIds } = useFieldManualVehicleIdsRead(userInputKey);

  const initialBoomConfigurationsManual$data = useConstantValue(() => {
    if (initialValuesManual$data?.userInput?.boomConfiguration == null) {
      return [];
    }

    const primary = {
      type: 'principal',
      ...initialValuesManual$data.userInput.boomConfiguration,
      deletedAt: null,
    } as const;
    const additionals = initialValuesManual$data?.additionalConfigurations.map((a) =>
      a.boomConfiguration?.id
        ? ({
            type: 'additional',
            ...a.boomConfiguration,
            deletedAt: a.deletedAt ?? null,
          } as const)
        : null,
    );
    return [primary, ...additionals];
  });

  const queryResult$key: PluralKey<useFavoriteEquipment_extraDataFragment$key> = initialBoomConfigurationsManual$data;
  const staticData$data = useStaticData(queryResult$key);

  return useState<Equipments>(() => {
    // when we don't have a boom configuration, we return a single null equipment
    if (!initialBoomConfigurationsManual$data[0] || !staticData$data?.[0]) {
      return { dirty: false, values: [equipmentNullFactory()] };
    }
    return {
      dirty: false,
      values: initialBoomConfigurationsManual$data.map((equipment, index) => {
        return equipment == null
          ? equipmentNullFactory()
          : equipment.type === 'principal'
            ? {
                key: nanoid(),
                boomConfigurationId: equipment.id,
                active: true,
                data: staticData$data?.[index] ?? _throw('Additional data length for favorite equipment mismatched.'),
                boom: {
                  ...defaultEquipmentBoom,
                  boomLength: boomLength,
                },
                jib: {
                  ...defaultEquipmentJib,
                  jibLength: jibLength,
                  offsetAngle: offsetAngle,
                },
                ...defaultEquipmentInfo,
                radius: radius,
                maxWeight: maxWeight,
                counterweight: counterweight,
                vehicleIds: vehicleIds ?? [],
              }
            : {
                key: nanoid(),
                boomConfigurationId: equipment.id,
                active: !equipment.deletedAt,
                data: staticData$data?.[index] ?? _throw('Additional data length for favorite equipment mismatched.'),
                boom: {
                  ...defaultEquipmentBoom,
                  boomLength: Length.parse(equipment.boomLength),
                },
                jib: {
                  ...defaultEquipmentJib,
                  jibLength: Length.parse(equipment.jibLength),
                },
                ...defaultEquipmentInfo,
                counterweight: Mass.parse(equipment.counterweight),
                vehicleIds: equipment.vehicleIds ?? [],
              };
      }),
    };
  });
}

/**
 * When we change the "overrides" for the automatic configuration, we need to update the favorite equipment context
 * In this case the only overrides is the "active" status of the additional cranes
 * @param initialValue$key
 * @param setEquipmentAutomatic
 */
function useFavoriteEquipment_SynchronizeAutomatic_FromFieldValues(
  initialValue$key: useFavoriteEquipment_SynchronizeAutomatic_FromFieldValuesFragment$key | null | undefined,
  setEquipmentAutomatic: Dispatch<SetStateAction<Equipments>>,
) {
  const initialValues$data = useFragment(
    graphql`
      fragment useFavoriteEquipment_SynchronizeAutomatic_FromFieldValuesFragment on CraneSelectorInternal {
        ...CraneSelectorFields_FavoriteConfigurationFragment
        automaticConfiguration {
          ...AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment
        }
      }
    `,
    initialValue$key,
  );

  const { favoriteConfigurationETag, favoriteConfigurationETagIsDirty } = useFieldFavoriteConfigurationETag(initialValues$data);
  const { additionalCranesAutomatic, additionalCranesAutomaticAreDirty } = useFieldAdditionalCranesAutomaticCollection(
    initialValues$data?.automaticConfiguration,
  );

  const isDirty = favoriteConfigurationETagIsDirty || additionalCranesAutomaticAreDirty;

  const updateFavorite = useEffectEvent((deps: readonly Readonly<{ deletedAt?: DateTime<true> | null | undefined }>[]) => {
    if (!isDirty) {
      return;
    }
    if (!favoriteConfigurationETag) {
      setEquipmentAutomatic({ dirty: isDirty, values: [equipmentNullFactory()] });
    } else {
      setEquipmentAutomatic((prevState) => {
        const previousPrimary = prevState.values[0];
        if (previousPrimary?.boomConfigurationId == null) {
          // if we throw here, it's because this was called when it shouldn't have.
          throw new Error('Invalid operation. Trying to update automatic favorite equipment in an unsupported state.');
        }
        return {
          dirty: isDirty,
          values: [
            previousPrimary,
            ...deps.map((additionalCraneDeletedAt, index) => {
              const previousAdditional = prevState.values[index + 1];
              return {
                ...(previousAdditional?.boomConfigurationId ? previousAdditional : equipmentNullFactory()),
                active: !additionalCraneDeletedAt.deletedAt,
              };
            }),
          ],
        };
      });
    }
  });

  const additionalsDeletedAt = useArrayLens(
    additionalCranesAutomatic.filter((c) => !c.$removed),
    'deletedAt',
  );

  useEffect(() => {
    updateFavorite(additionalsDeletedAt);
  }, [additionalsDeletedAt, updateFavorite]);
}

/**
 * When we change the "overrides" for the manual configuration, we need to update the favorite equipment context
 * In this case the overrides are every field we can edit after picking a boom configuration and the active status of the additional cranes
 * @param initialValue$key
 * @param setEquipmentManual
 */
function useFavoriteEquipment_SynchronizeManual_FromFieldValues(
  initialValue$key: useFavoriteEquipment_SynchronizeManual_FromFieldValuesFragment$key | null | undefined,
  setEquipmentManual: Dispatch<SetStateAction<Equipments>>,
) {
  const { endOperation: endOperationForPrimary } = useOperations(FAVORITE_EQUIPMENT_PRIMARY_UPDATE_OPERATION_KEY);
  const { endOperation: endOperationForAdditionals } = useOperations(FAVORITE_EQUIPMENT_ADDITIONALS_UPDATE_OPERATION_KEY);
  const initialValues$data = useFragment(
    graphql`
      fragment useFavoriteEquipment_SynchronizeManual_FromFieldValuesFragment on ManualConfigurationInternal {
        ...AdditionalCranesFields_AdditionalCranesManualCollectionFragment
        userInput {
          ...CraneSelectorFields_Manual_BoomConfigurationFragment
          ...CraneSelectorFields_Manual_BoomLengthFragment
          ...CraneSelectorFields_Manual_JibLengthFragment
          ...CraneSelectorFields_Manual_OffsetAngleFragment
          ...CraneSelectorFields_Manual_RadiusFragment
          ...CraneSelectorFields_Manual_MaxWeightFragment
          ...CraneSelectorFields_Manual_CounterweightFragment
          ...CraneSelectorFields_VehicleIdsFragment
          boomConfiguration {
            ...useFavoriteEquipment_extraDataFragment
          }
        }
      }
    `,
    initialValue$key,
  );

  const userInputKey = initialValues$data?.userInput;
  const { boomConfiguration, boomConfigurationIsDirty } = useFieldManualBoomConfigurationRead(userInputKey);
  const { offsetAngle, offsetAngleIsDirty } = useFieldOffsetAngleRead(userInputKey);
  const { boomLength, boomLengthIsDirty } = useFieldBoomLengthRead(userInputKey);
  const { jibLength, jibLengthIsDirty } = useFieldJibLengthRead(userInputKey);
  const { radius, radiusIsDirty } = useFieldRadiusRead(userInputKey);
  const { maxWeight, maxWeightIsDirty } = useFieldMaxWeightRead(userInputKey);
  const { counterweight, counterweightIsDirty } = useFieldCounterweightRead(userInputKey);
  const { additionalCranesManual, additionalCranesManualAreDirty } = useFieldAdditionalCranesManualCollection(initialValues$data);
  const { vehicleIds, vehicleIdsIsDirty } = useFieldManualVehicleIdsRead(userInputKey);

  const isDirty =
    boomConfigurationIsDirty ||
    offsetAngleIsDirty ||
    boomLengthIsDirty ||
    jibLengthIsDirty ||
    radiusIsDirty ||
    maxWeightIsDirty ||
    counterweightIsDirty ||
    additionalCranesManualAreDirty ||
    vehicleIdsIsDirty;

  const updateFavorite = useEffectEvent(
    (deps: {
      boomLength: Length | null;
      jibLength: Length | null;
      counterweight: Mass | null;
      maxWeight: Mass | null;
      offsetAngle: Angle | null;
      radius: Length | null;
      vehicleIds: readonly { readonly key: string; readonly label: string | null | undefined }[];
      additionalsDeletedAt: readonly Readonly<{ deletedAt?: DateTime<true> | null | undefined }>[];
    }) => {
      endOperationForPrimary();
      endOperationForAdditionals();
      if (!isDirty) {
        return;
      }
      if (!boomConfiguration) {
        setEquipmentManual({ dirty: isDirty, values: [equipmentNullFactory()] });
      } else {
        setEquipmentManual((prevState) => {
          const previousPrimary = prevState.values[0];
          if (previousPrimary?.boomConfigurationId == null) {
            // if we throw here, it's because this was called when it shouldn't have.
            throw new Error('Invalid operation. Trying to update manual favorite equipment in an unsupported state.');
          }
          return {
            dirty: isDirty,
            values: [
              {
                key: previousPrimary.key ?? nanoid(),
                boomConfigurationId: previousPrimary.boomConfigurationId,
                boomConfigurationName: previousPrimary.boomConfigurationName,
                active: previousPrimary.active,
                data: previousPrimary.data,
                boom: {
                  boomLength: deps.boomLength,
                  boomExtensionLength: null,
                },
                jib: previousPrimary.jib
                  ? {
                      jibKind: previousPrimary.jib.jibKind,
                      jibLength: deps.jibLength,
                      offsetAngle: deps.offsetAngle,
                      jibExtensionLength: null,
                      mainBoomAngleOffset: null,
                    }
                  : null,
                radius: deps.radius,
                maxWeight: deps.maxWeight,
                counterweight: deps.counterweight,
                vehicleIds: deps.vehicleIds ?? null,
                usagePercentage: null,
              },
              ...deps.additionalsDeletedAt.map((additionalCraneDeletedAt, index) => {
                const previousAdditional = prevState.values[index + 1];
                return {
                  ...(previousAdditional?.boomConfigurationId != null ? previousAdditional : equipmentNullFactory()),
                  active: !additionalCraneDeletedAt.deletedAt,
                };
              }),
            ],
          };
        });
      }
    },
  );

  // use array lens to avoid using the additionalCranesManual collection in the useEffect and cause unwanted re-render
  const additionalsDeletedAt = useArrayLens(
    additionalCranesManual.filter((c) => !c.$removed),
    'deletedAt',
  );

  useEffect(() => {
    updateFavorite({
      boomLength,
      jibLength,
      counterweight,
      maxWeight,
      offsetAngle,
      radius,
      vehicleIds: vehicleIds ?? [],
      additionalsDeletedAt,
    });
  }, [additionalsDeletedAt, boomLength, counterweight, jibLength, maxWeight, offsetAngle, radius, updateFavorite, vehicleIds]);
}

/**
 * This hook is responsible for updating the manual boom configuration fields when the boom configuration id changes.
 *
 * @param initialValue$key
 * @param disabled
 * @param required
 */
function useUpdateManualBoomConfigurationFields(
  initialValue$key: useFavoriteEquipment_useUpdateBoomConfigurationInfo_initialValueFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const { endOperation: endOperationForPrimary } = useOperations(FAVORITE_EQUIPMENT_PRIMARY_UPDATE_OPERATION_KEY);

  const [boomConfig$key] = useSharedState(jobSharedStateContext, useUpdateBoomConfigurationInfoKey);
  const [additionalCrane$keys] = useSharedState(jobSharedStateContext, useUpdateManualAdditionalConfigurationFieldsKey);

  const initialValue$data = useFragment(
    graphql`
      fragment useFavoriteEquipment_useUpdateBoomConfigurationInfo_initialValueFragment on CraneSelectorInternal {
        manualConfiguration {
          ...AdditionalCranesFields_AdditionalCranesManualCollectionFragment
          userInput {
            ...CraneSelectorFields_Manual_BoomLengthFragment
            ...CraneSelectorFields_Manual_JibLengthFragment
            ...CraneSelectorFields_Manual_CounterweightFragment
            ...CraneSelectorFields_Manual_OffsetAngleFragment
            ...CraneSelectorFields_Manual_RadiusFragment
            ...CraneSelectorFields_Manual_MaxWeightFragment
          }
        }
      }
    `,
    initialValue$key,
  );

  const { setBoomLength } = useFieldBoomLength(initialValue$data?.manualConfiguration.userInput, disabled, required);
  const { setJibLength } = useFieldJibLength(initialValue$data?.manualConfiguration.userInput, disabled, required);
  const { setCounterweight } = useFieldCounterweight(initialValue$data?.manualConfiguration.userInput, disabled);
  const { setOffsetAngle } = useFieldOffsetAngle(initialValue$data?.manualConfiguration.userInput, disabled);
  const { setRadius } = useFieldRadius(initialValue$data?.manualConfiguration.userInput, disabled, required);
  const { setMaxWeight } = useFieldMaxWeight(initialValue$data?.manualConfiguration.userInput, disabled, required);

  const { appendAdditionalCraneManual, clearAdditionalCranesManual } = useFieldAdditionalCranesManualCollection(
    initialValue$data?.manualConfiguration,
  );

  const boomConfig$data = useFragment(
    graphql`
      fragment useFavoriteEquipment_useUpdateBoomConfigurationInfoFragment on BoomConfigurationSnapshot {
        boomLength
        jibLength
        counterweight
      }
    `,
    boomConfig$key,
  );

  const additionalCrane$data = useFragment(
    graphql`
      fragment useFavoriteEquipment_useUpdateManualBoomConfigurationFields_AdditionalBoomConfigurationFragment on BoomConfigurationSnapshot
      @relay(plural: true) {
        capacity
        equipmentKind {
          id
          code
          label
        }
        configurationKind {
          id
          code
          label
        }
        id
        label
      }
    `,
    additionalCrane$keys,
  );

  const updateBoomConfig = useEffectEvent((data: useFavoriteEquipment_useUpdateBoomConfigurationInfoFragment$data) => {
    setBoomLength(Length.parse(data.boomLength));
    setJibLength(Length.parse(data.jibLength));
    setCounterweight(Mass.parse(data.counterweight));
    setOffsetAngle(null);
    setRadius(null);
    setMaxWeight(null);
  });

  const updateManualAdditionalCranes = useEffectEvent(
    (deps: useFavoriteEquipment_useUpdateManualBoomConfigurationFields_AdditionalBoomConfigurationFragment$data) => {
      clearAdditionalCranesManual();

      for (const data of deps) {
        appendAdditionalCraneManual({
          id: 'new',
          deletedAt: null,
          capacity: {
            capacity: data.capacity,
            label: `${data.capacity}`,
          },
          equipmentKind: data.equipmentKind ?? null,
          configurationKind: data.configurationKind ?? null,
          boomConfiguration: {
            id: data.id,
            label: data.label,
          },
        });
      }
    },
  );

  useEffect(() => {
    if (!boomConfig$data || !boomConfig$key) {
      endOperationForPrimary();
      return;
    }

    updateBoomConfig(boomConfig$data);
    endOperationForPrimary();
  }, [boomConfig$data, boomConfig$key, endOperationForPrimary, updateBoomConfig]);

  useEffect(() => {
    if (additionalCrane$keys === undefined) {
      endOperationForPrimary();
      return;
    }

    updateManualAdditionalCranes(additionalCrane$data ?? []);
    endOperationForPrimary();
  }, [additionalCrane$data, additionalCrane$keys, endOperationForPrimary, updateManualAdditionalCranes]);
}

/**
 * This hook is responsible for updating the automatic boom configuration fields when the selected matching configuration changes.
 *
 * @param initialValue$key
 */
function useUpdateAutomaticAdditionalConfigurationFields(
  initialValue$key: useFavoriteEquipment_useUpdateAutomaticAdditionalConfigurationFields_InitialValueFragment$key | null | undefined,
) {
  const { endOperation: endOperationForPrimary } = useOperations(FAVORITE_EQUIPMENT_PRIMARY_UPDATE_OPERATION_KEY);
  const { endOperation: endOperationForAdditionals } = useOperations(FAVORITE_EQUIPMENT_ADDITIONALS_UPDATE_OPERATION_KEY);
  const [updateAutomaticAdditionalConfiguration$keys] = useSharedState(
    jobSharedStateContext,
    useUpdateAutomaticAdditionalConfigurationFieldsKey,
  );

  const $data = useFragment(
    graphql`
      fragment useFavoriteEquipment_useUpdateAutomaticAdditionalConfigurationFieldsFragment on BoomConfigurationSnapshot
      @relay(plural: true) {
        capacity
        equipmentKind {
          id
          code
          label
        }
        configurationKind {
          id
          code
          label
        }
        id
        label
      }
    `,
    updateAutomaticAdditionalConfiguration$keys,
  );

  const initialValue$data = useFragment(
    graphql`
      fragment useFavoriteEquipment_useUpdateAutomaticAdditionalConfigurationFields_InitialValueFragment on CraneSelectorInternal {
        automaticConfiguration {
          ...AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment
        }
      }
    `,
    initialValue$key,
  );

  const { appendAdditionalCraneAutomatic, clearAdditionalCranesAutomatic } = useFieldAdditionalCranesAutomaticCollection(
    initialValue$data?.automaticConfiguration,
  );

  const updateAutomaticAdditionalCranes = useEffectEvent(
    (additionalCranes: useFavoriteEquipment_useUpdateAutomaticAdditionalConfigurationFieldsFragment$data) => {
      endOperationForPrimary();
      endOperationForAdditionals();
      clearAdditionalCranesAutomatic();
      for (const data of additionalCranes) {
        appendAdditionalCraneAutomatic({
          id: 'new',
          deletedAt: null,
          capacity: {
            capacity: data.capacity,
            label: `${data.capacity}`,
          },
          equipmentKind: data.equipmentKind ?? null,
          configurationKind: data.configurationKind ?? null,
          boomConfiguration: {
            id: data.id,
            label: data.label,
          },
        });
      }
    },
  );

  useEffect(() => {
    if (!updateAutomaticAdditionalConfiguration$keys) return;
    updateAutomaticAdditionalCranes($data ?? []);
  }, [$data, updateAutomaticAdditionalConfiguration$keys, updateAutomaticAdditionalCranes]);
}

/**
 * Reset the boom configuration when the capacity, equipment kind or configuration kind changes
 *
 * @param initialValues$key
 * @param disabled
 * @param required
 */
function useResetBoomConfiguration(
  initialValues$key: useFavoriteEquipment_useResetBoomConfigurationFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const initialValues$data = useFragment(
    graphql`
      fragment useFavoriteEquipment_useResetBoomConfigurationFragment on ManualConfigurationInfo {
        ...CraneSelectorFields_CraneCapacityFragment
        ...CraneSelectorFields_EquipmentKindFragment
        ...CraneSelectorFields_CraneConfigurationKindFragment
        ...CraneSelectorFields_Manual_BoomConfigurationFragment
      }
    `,
    initialValues$key,
  );

  const { setBoomConfiguration, boomConfiguration } = useFieldManualBoomConfiguration(initialValues$data, disabled, required);
  const { capacity, capacityIsDirty } = useFieldManualCapacityRead(initialValues$data);
  const { equipmentKind, equipmentKindIsDirty } = useFieldManualEquipmentKindRead(initialValues$data);
  const { configurationKind, configurationKindIsDirty } = useFieldManualConfigurationKindRead(initialValues$data);

  const resetBoomConfiguration = useEffectEvent(
    (deps: { capacity: typeof capacity; equipmentKind: typeof equipmentKind; configurationKind: typeof configurationKind }) => {
      if (boomConfiguration == null) {
        return;
      }

      if (!deps.capacity || !deps.equipmentKind || !deps.configurationKind) {
        setBoomConfiguration(null);
      }
    },
  );
  useEffect(() => {
    if (!capacityIsDirty && !equipmentKindIsDirty && !configurationKindIsDirty) {
      return;
    }
    resetBoomConfiguration({ capacity, equipmentKind, configurationKind });
  }, [capacity, capacityIsDirty, configurationKind, configurationKindIsDirty, equipmentKind, equipmentKindIsDirty, resetBoomConfiguration]);
}
