import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useConstantValue } from '../common/hooks/useConstantValue';
import { DateTime } from 'luxon';
import { QuoteFilters_Representative, QuoteResponsiveGridFilters_Representative } from './QuoteFilters';
import {
  ArrivalDateFilter,
  CraneCapacityFilter,
  DispatchBranchFilter,
  EquipmentKindFilter,
  JobKindFilter,
  JobStatusFilter,
  ProjectManagerFilter,
  TextSearchFilter,
  useJobFilters,
} from '../jobs/JobFilters';
import { QuoteList_RepresentativeFragment$key } from './__generated__/QuoteList_RepresentativeFragment.graphql';
import { quoteFiltersSharedStateKey } from './QuoteList.Page';
import { ResponsiveGridFilters, ResponsiveGridForwardProps } from '../common/components/ResponsiveGrid';
import { AuthorizationWriteFragment$key } from '../auth/__generated__/AuthorizationWriteFragment.graphql';
import { Theme, useMediaQuery } from '@mui/material';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { discriminate, isDefined } from '../common/utils/typeUtils';
import { quoteKinds } from '../__enums__/QuoteKind';
import { quoteStatuses } from '../__enums__/QuoteStatus';
import { QuoteStatusChip } from './QuoteStatusChip';
import { QuoteList_Representative_FiltersFragment$key } from './__generated__/QuoteList_Representative_FiltersFragment.graphql';
import { Outlet } from 'react-router';

export type QuoteList_RepresentativeContextValue = {
  write$key: AuthorizationWriteFragment$key;
  filters: QuoteFilters_Representative;
  setFilters: Dispatch<SetStateAction<QuoteFilters_Representative>>;
  listProps: ResponsiveGridForwardProps;
};

export function QuoteList_Representative({
  $key,
  write$key,
  ...listProps
}: {
  $key: QuoteList_RepresentativeFragment$key;
  write$key: AuthorizationWriteFragment$key;
} & ResponsiveGridForwardProps) {
  const $data = useFragment(
    graphql`
      fragment QuoteList_RepresentativeFragment on Employee {
        ...JobFilters_useJobFiltersFragment
      }
    `,
    $key,
  );

  const salesDefaultFilters = useConstantValue(() => {
    const today = DateTime.now().startOf('day');

    return QuoteFilters_Representative.EMPTY.with({
      arrivalDate: { start: today, end: today.plus({ day: 90 }) },
      statuses: ['inApproval', 'inWriting', 'revising', 'awaitingClient'],
    });
  });

  const [filters, setFilters] = useJobFilters<QuoteFilters_Representative>(
    $data,
    quoteFiltersSharedStateKey,
    QuoteFilters_Representative.EMPTY,
    salesDefaultFilters,
    (sp) => QuoteFilters_Representative.fromSearchParams(sp),
  );

  const contextValue: QuoteList_RepresentativeContextValue = {
    write$key: write$key,
    filters,
    setFilters,
    listProps,
  };

  return <Outlet context={contextValue} />;
}

export function QuoteList_Representative_Filters({
  $key,
  filters,
  onFiltersChange: handleFiltersChange,
}: {
  $key: QuoteList_Representative_FiltersFragment$key;
  filters: QuoteFilters_Representative;
  onFiltersChange: (filters: QuoteFilters_Representative) => void;
}) {
  const { t } = useAmbientTranslation();
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const $data = useFragment(
    graphql`
      fragment QuoteList_Representative_FiltersFragment on Query
      @argumentDefinitions(
        dispatchBranchIds: { type: "[ID!]!" }
        equipmentKindCodes: { type: "[Int!]!" }
        projectManagerIds: { type: "[ID!]!" }
      ) {
        dispatchBranches: nodes(ids: $dispatchBranchIds) {
          __typename
          ... on Branch {
            id
            label
            deletedAt
          }
        }
        equipmentKinds(codes: $equipmentKindCodes) {
          ... on EquipmentKindLookup {
            id
            code
            label
          }
        }
        projectManagers: nodes(ids: $projectManagerIds) {
          __typename
          ... on Representative {
            id
            label
            deletedAt
          }
        }
      }
    `,
    $key,
  );

  const responsiveGridFilters: QuoteResponsiveGridFilters_Representative = useMemo(() => {
    // Adding the extra data required by the responsive grid filter components to the filters should only be done once,
    // since this data is only fetched once during list page load. So once the filters have been modified by the user,
    // this extra data might not be up-to-date anymore, and overwriting the filters with it might corrupt the filters.
    // This is safe since the autocompletes which require this data also put it back in the filters on modification.
    if (filters instanceof QuoteResponsiveGridFilters_Representative) return filters;
    return filters.toResponsiveGridFilters({
      dispatchBranches: $data.dispatchBranches.filter(isDefined).filter(discriminate('__typename', 'Branch')),
      equipmentKinds: $data.equipmentKinds,
      projectManagers: $data.projectManagers.filter(isDefined).filter(discriminate('__typename', 'Representative')),
    });
  }, [filters, $data]);

  return (
    <ResponsiveGridFilters<QuoteResponsiveGridFilters_Representative>
      filters={responsiveGridFilters}
      emptyFilters={QuoteResponsiveGridFilters_Representative.EMPTY}
      onFiltersChange={handleFiltersChange}
      compact={compact}
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: { mx: '1rem' },
        [theme.breakpoints.up('md')]: { justifyContent: 'flex-start' },
      })}
      elements={(mode, state, setState) => [
        mode === 'inline' && (
          <TextSearchFilter
            key='fts'
            value={state.get('searchTerm')}
            placeHolder={t('search', { ns: 'serviceCall' })}
            onChange={(searchTerm) => setState((prev) => prev.with({ searchTerm }))}></TextSearchFilter>
        ),
        mode === 'dialog' && (
          <JobKindFilter
            key='kind'
            value={state.get('kinds')}
            label={t('list.column.kind')}
            options={quoteKinds}
            onChange={(kinds) => setState((prev) => prev.with({ kinds }))}></JobKindFilter>
        ),
        mode === 'dialog' && (
          <CraneCapacityFilter
            key='capacity'
            value={state.get('capacities')}
            onChange={(value) => setState((prev) => prev.with({ capacities: value.map(({ capacity }) => capacity) }))}
          />
        ),
        mode === 'dialog' && (
          <EquipmentKindFilter
            key='equipmentKind'
            value={state.get('equipmentKinds')}
            onChange={(equipmentKinds) => setState((prev) => prev.with({ equipmentKinds }))}
          />
        ),
        mode === 'dialog' && (
          <JobStatusFilter
            key='status'
            value={state.get('statuses')}
            options={quoteStatuses.filter((s) => s !== 'locked')}
            onChange={(statuses) => setState((prev) => prev.with({ statuses }))}
            label={t('list.column.status')}
            renderChip={(option, handleDelete) => <QuoteStatusChip key={option} statuses={[option]} onDelete={handleDelete} />}
          />
        ),
        mode === 'dialog' && (
          <DispatchBranchFilter
            key='dispatchBranch'
            value={state.get('dispatchBranches')}
            onChange={(dispatchBranches) => setState((prev) => prev.with({ dispatchBranches }))}
          />
        ),
        mode === 'dialog' && (
          <ProjectManagerFilter
            key='projectManager'
            value={state.get('projectManagers')}
            onChange={(projectManagers) => setState((prev) => prev.with({ projectManagers }))}
          />
        ),
        mode === 'dialog' && (
          <ArrivalDateFilter
            key='arrivalDate'
            value={state.get('arrivalDate')}
            onChange={(arrivalDate) => setState((prev) => prev.with({ arrivalDate }))}
          />
        ),
      ]}
    />
  );
}
