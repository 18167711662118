import { DateTime, DateTimeMaybeValid, Duration } from 'luxon';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import frenchStrings from 'react-timeago/lib/language-strings/fr';
import englishStrings from 'react-timeago/lib/language-strings/en';

// In C#, the min value for DateTime is "0001-01-01". This constant is used to limit to this in the frontend.
export const MIN_DATE_TIME: DateTime<true> = (() => {
  const dt = DateTime.fromObject({ year: 1 }, { zone: 'UTC' });
  if (!dt.isValid) throw new Error('Invalid MIN_DATE_TIME');
  return dt;
})();

export const defaultTimezone = 'America/Montreal';

export const timeFormat = 'HH:mm';

export const dateTimeFormat = 'dd/MM/yyyy HH:mm';

export const dateFormat = 'dd/MM/yyyy';

export const roundDateTimeToMinuteStep = (dateTime: DateTime<true>, minuteStep: number): DateTime<true> => {
  if (minuteStep <= 0) throw new Error('minuteStep must be a positive number');
  const minute = Math.round(dateTime.minute / minuteStep) * minuteStep;
  const rounded = dateTime.set({ minute, second: 0, millisecond: 0 });
  return rounded.hasSame(dateTime, 'day') ? rounded : rounded.minus({ minute: minuteStep }); // prevent overflow to next day
};

/**
 * Returns a TimeAgo formatter for the specified language.
 * Since a missing translation here is low-value, we fall back to the default formatter to avoid crashing the
 * app in the event that a new language is added.
 * @param language - Language code
 */
export const timeAgoFormatter = (language: string): TimeAgo.Formatter | undefined => {
  const languageTwoLetterCode = language.substring(0, 2);
  switch (languageTwoLetterCode) {
    case 'fr':
      return buildFormatter(frenchStrings);
    case 'en':
      return buildFormatter(englishStrings);
    default:
      return buildFormatter(englishStrings);
  }
};

/**
 * Parse a nullable ISO date string into a VALID {@link DateTime} object. Returns `null` if the argument is either
 * `null` or `undefined`. Throws an error if the argument is not a valid ISO date string.
 * @param rawValue - An ISO date string or `null` or `undefined`.
 * @throws Error - If the argument is not a valid ISO date string or `null` or `undefined`.
 */
export function parseDateTime(rawValue: string): DateTime<true>;
export function parseDateTime(rawValue: string | null | undefined): DateTime<true> | null;
export function parseDateTime(rawValue: string | null | undefined): DateTime<true> | null {
  if (rawValue == null) return null;

  const dateTime = DateTime.fromISO(rawValue);
  if (!dateTime.isValid) {
    throw new Error(
      `Failed to parse datetime string ${JSON.stringify(rawValue)} (${dateTime.invalidReason}: ${dateTime.invalidExplanation})`,
    );
  }

  return dateTime;
}

// TODO the serialization format being used here is meant to represent durations, not times. Fix?
/**
 * Parse a nullable ISO duration string in format 'PT0S' (00:00), 'PT1H' (01:00), 'PT12H' (12:00), 'PT1H5M' (01:05),
 * 'PT1H30M' (01:30), 'PT30M' (00:30) into a VALID {@link DateTime} object. Returns `null` if the argument is either
 * `null` or `undefined`. Throws an error if the argument is not a valid ISO duration string.
 * @param rawValue - An ISO duration string or `null` or `undefined`.
 * @throws Error - If the argument is not a valid ISO duration string or `null` or `undefined`.
 */
export function parseTimeOnly(rawValue: string): DateTime<true>;
export function parseTimeOnly(rawValue: string | null | undefined): DateTime<true> | null;
export function parseTimeOnly(rawValue: string | null | undefined): DateTime<true> | null {
  if (rawValue == null) return null;

  const duration = Duration.fromISO(rawValue);
  if (!duration.isValid) {
    throw new Error(
      `Failed to parse time string ${JSON.stringify(rawValue)} as duration (${duration.invalidReason}: ${duration.invalidExplanation})`,
    );
  }

  const dateTime = DateTime.fromObject({ hour: duration.hours, minute: duration.minutes });
  if (!dateTime.isValid) {
    throw new Error(
      `Failed to parse time string ${JSON.stringify(rawValue)} as datetime (${dateTime.invalidReason}: ${dateTime.invalidExplanation})`,
    );
  }

  return dateTime;
}

/**
 * Serialize a potentially invalid {@link DateTime} object into an ISO string. Returns `null` if the argument is `null`,
 * or `undefined` if the argument is `undefined`. Throws an error if the argument is an invalid {@link DateTime} object.
 * @param dateTime - A {@link DateTime} object or `null` or `undefined`.
 * @throws Error - If the argument is not a valid {@link DateTime} object or `null` or `undefined`.
 */
export function serializeDateTime(dateTime: DateTimeMaybeValid): string;
export function serializeDateTime(dateTime: DateTimeMaybeValid | null): string | null;
export function serializeDateTime(dateTime: DateTimeMaybeValid | undefined): string | undefined;
export function serializeDateTime(dateTime: DateTimeMaybeValid | null | undefined): string | null | undefined;
export function serializeDateTime(dateTime: DateTimeMaybeValid | null | undefined): string | null | undefined {
  if (dateTime == null) return dateTime;

  if (!dateTime.isValid) {
    throw new Error(`Cannot serialize invalid datetime (${dateTime.invalidReason}: ${dateTime.invalidExplanation})`);
  }

  return dateTime.toISO();
}
