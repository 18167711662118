import { Theme, useMediaQuery } from '@mui/material';
import { DataID, useFragment, useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {
  getNodeById,
  ResponsiveGrid,
  ResponsiveGridFilters,
  ResponsiveGridForwardProps,
  ResponsiveGridImperativeHandle,
  ResponsiveGridProps,
  useElementFactory,
  useSkeletonFactory,
} from '../common/components/ResponsiveGrid';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useRef } from 'react';
import { ServiceCallStatusChip } from './ServiceCallStatusChip';
import { serviceCallKinds } from '../__enums__/ServiceCallKind';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { serviceCallStatuses } from '../__enums__/ServiceCallStatus';
import {
  ArrivalDateFilter,
  CraneCapacityFilter,
  DispatchBranchFilter,
  EquipmentKindFilter,
  JobKindFilter,
  JobStatusFilter,
  ProjectManagerFilter,
  RepresentativeFilter,
  SubRegionFilter,
  TextSearchFilter,
  useJobFilters,
} from '../jobs/JobFilters';
import { AuthorizationWriteFragment$key } from '../auth/__generated__/AuthorizationWriteFragment.graphql';
import { convertToTsQuery } from '../common/utils/stringUtils';
import { discriminate, isDefined } from '../common/utils/typeUtils';
import { ServiceCallFilters_Regular, ServiceCallResponsiveGridFilters_Regular } from './ServiceCallFilters';
import { useEvent } from '../common/utils/effectUtils';
import { useConstantValue } from '../common/hooks/useConstantValue';
import { serviceCallFiltersSharedStateKey } from './ServiceCallList.Page';
import {
  getServiceCallListColumns,
  ServiceCallList_Item,
  ServiceCallList_ListSkeleton,
  ServiceCallList_Row,
  ServiceCallList_RowSkeleton,
  serviceCallListSx,
} from './ServiceCallList';
import { ServiceCallList_RegularFragment$key } from './__generated__/ServiceCallList_RegularFragment.graphql';
import { ServiceCallList_RegularQuery, ServiceCallList_RegularQuery$variables } from './__generated__/ServiceCallList_RegularQuery.graphql';
import { ServiceCallList_Regular_ListContentFragment$key } from './__generated__/ServiceCallList_Regular_ListContentFragment.graphql';
import { ServiceCallList_Regular_FiltersFragment$key } from './__generated__/ServiceCallList_Regular_FiltersFragment.graphql';

export function ServiceCallList_Regular({
  $key,
  write$key,
  ...listProps
}: {
  $key: ServiceCallList_RegularFragment$key;
  write$key: AuthorizationWriteFragment$key;
} & ResponsiveGridForwardProps) {
  const $data = useFragment(
    graphql`
      fragment ServiceCallList_RegularFragment on Employee {
        ...JobFilters_useJobFiltersFragment
        roles
        supervisedRegions {
          subRegions {
            id
          }
        }
      }
    `,
    $key,
  );

  const salesDefaultFilters = useConstantValue(() => {
    const today = DateTime.now().startOf('day');
    const isSalesSupervisor = $data.roles.includes('salesSupervisor');
    const subRegionIds = $data.supervisedRegions?.flatMap((r) => r?.subRegions.map((sr) => ({ id: sr.id })) ?? []) ?? [];

    return ServiceCallFilters_Regular.EMPTY.with({
      arrivalDate: { start: today, end: today.plus({ day: 90 }) },
      statuses: ['inWriting', 'reserved', 'inModification', 'delayed', 'transferred'],
      subRegions: isSalesSupervisor ? subRegionIds : [],
    });
  });

  const [filters, handleFiltersChange] = useJobFilters<ServiceCallFilters_Regular>(
    $data,
    serviceCallFiltersSharedStateKey,
    ServiceCallFilters_Regular.EMPTY,
    salesDefaultFilters,
    (sp) => ServiceCallFilters_Regular.fromSearchParams(sp),
  );

  const queryFilters = useConstantValue<ServiceCallList_RegularQuery$variables>(() => ({
    first: 25,
    where: filters.toJobRevisionFilter(),
    searchTerm: convertToTsQuery(filters.get('searchTerm'), '|'),
    projectManagerIds: filters.get('projectManagers').map(({ id }) => id),
    dispatchBranchIds: filters.get('dispatchBranches').map(({ id }) => id),
    equipmentKindCodes: filters.get('equipmentKinds').map(({ code }) => code),
    representativeIds: filters.get('representatives').map(({ id }) => id),
    subRegionIds: filters.get('subRegions').map(({ id }) => id),
  }));

  const serviceCalls = useLazyLoadQuery<ServiceCallList_RegularQuery>(
    graphql`
      query ServiceCallList_RegularQuery(
        $searchTerm: String
        $first: Int
        $where: ServiceCallJobRevisionFilterType
        $dispatchBranchIds: [ID!]!
        $equipmentKindCodes: [Int!]!
        $projectManagerIds: [ID!]!
        $representativeIds: [ID!]!
        $subRegionIds: [ID!]!
      ) {
        ...ServiceCallList_Regular_ListContentFragment
          @arguments(
            searchTerm: $searchTerm
            representativeIds: $representativeIds
            subRegionIds: $subRegionIds
            first: $first
            where: $where
          )
        ...ServiceCallList_Regular_FiltersFragment
          @arguments(
            dispatchBranchIds: $dispatchBranchIds
            equipmentKindCodes: $equipmentKindCodes
            projectManagerIds: $projectManagerIds
            representativeIds: $representativeIds
            subRegionIds: $subRegionIds
          )
      }
    `,
    queryFilters,
    { fetchPolicy: 'store-and-network' }, // store-and-network to make sure the data is up to date following a details page modification
  );

  return (
    <ListContent
      {...listProps}
      $key={serviceCalls}
      write$key={write$key}
      filters$key={serviceCalls}
      filters={filters}
      onFiltersChange={handleFiltersChange}
    />
  );
}

export function ListContent({
  $key,
  filters$key,
  write$key,
  filters,
  onFiltersChange: handleFiltersChange,
  onItemClick,
  ...gridProps
}: {
  $key: ServiceCallList_Regular_ListContentFragment$key;
  filters$key: ServiceCallList_Regular_FiltersFragment$key;
  write$key: AuthorizationWriteFragment$key;
  filters: ServiceCallFilters_Regular;
  onFiltersChange: (filters: ServiceCallFilters_Regular) => void;
} & ResponsiveGridForwardProps) {
  const gridRef = useRef<ResponsiveGridImperativeHandle | null>(null);

  const { t } = useAmbientTranslation();
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const [$data, refetch] = useRefetchableFragment<ServiceCallList_RegularQuery, ServiceCallList_Regular_ListContentFragment$key>(
    graphql`
      fragment ServiceCallList_Regular_ListContentFragment on Query
      @refetchable(queryName: "ServiceCallList_Regular_ListContentFragmentQuery")
      @argumentDefinitions(
        searchTerm: { type: "String" }
        representativeIds: { type: "[ID!]" }
        subRegionIds: { type: "[ID!]" }
        after: { type: "String" }
        before: { type: "String" }
        first: { type: "Int" }
        last: { type: "Int" }
        where: { type: "ServiceCallJobRevisionFilterType" }
      ) {
        searchServiceCalls(
          searchTerm: $searchTerm
          representativeIds: $representativeIds
          subRegionIds: $subRegionIds
          after: $after
          before: $before
          first: $first
          last: $last
          where: $where
          order: [
            { snapshot: { statusOrder: ASC } }
            { snapshot: { projectBase: { arrivalDate: { date: ASC } } } }
            { snapshot: { equipmentBase: { craneSelector: { favoriteConfiguration: { capacity: DESC } } } } }
            { id: ASC }
          ]
        ) @required(action: THROW) {
          ...ResponsiveGridFragment
          edges {
            node {
              id
              lifeCycleBranchId
              ...ServiceCallList_RowFragment
              ...ServiceCallList_ItemFragment
            }
          }
        }
      }
    `,
    $key,
  );

  const edges = $data.searchServiceCalls?.edges;

  const refetchFn = useCallback<ResponsiveGridProps['refetch']>(
    (vars, options) =>
      refetch(
        {
          ...vars,
          searchTerm: convertToTsQuery(filters.get('searchTerm'), '|'),
          representativeIds: filters.get('representatives').map(({ id }) => id),
          subRegionIds: filters.get('subRegions').map(({ id }) => id),
          where: filters.toJobRevisionFilter(),
        },
        options,
      ),
    [refetch, filters],
  );

  const columns = getServiceCallListColumns(t, compact);

  const refreshServiceCallList = useEvent(() => gridRef.current?.goToFirstPage());

  const rowElementFactory = useElementFactory(edges, (node, orderByColumns) => (
    <ServiceCallList_Row
      $key={node}
      write$key={write$key}
      orderByColumns={orderByColumns}
      refreshServiceCallList={refreshServiceCallList}
    />
  ));
  const listElementFactory = useElementFactory(edges, (node) => <ServiceCallList_Item $key={node} />);
  const rowSkeletonFactory = useSkeletonFactory(() => <ServiceCallList_RowSkeleton columns={columns} />);
  const listSkeletonFactory = useSkeletonFactory(() => <ServiceCallList_ListSkeleton />);

  return (
    $data.searchServiceCalls && (
      <>
        <Filters $key={filters$key} filters={filters} onFiltersChange={handleFiltersChange} />
        <ResponsiveGrid
          ref={gridRef}
          connectionFragmentKey={$data.searchServiceCalls}
          refetch={refetchFn}
          columnDefinitions={columns}
          rowElementFactory={rowElementFactory}
          listElementFactory={listElementFactory}
          rowSkeletonFactory={rowSkeletonFactory}
          listSkeletonFactory={listSkeletonFactory}
          listSx={serviceCallListSx}
          onItemClick={(id: DataID) => onItemClick?.(getNodeById(id, edges).lifeCycleBranchId)}
          {...gridProps}
        />
      </>
    )
  );
}

function Filters({
  $key,
  filters,
  onFiltersChange: handleFiltersChange,
}: {
  $key: ServiceCallList_Regular_FiltersFragment$key;
  filters: ServiceCallFilters_Regular;
  onFiltersChange: (filters: ServiceCallResponsiveGridFilters_Regular) => void;
}) {
  const { t } = useAmbientTranslation();
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const $data = useFragment(
    graphql`
      fragment ServiceCallList_Regular_FiltersFragment on Query
      @argumentDefinitions(
        dispatchBranchIds: { type: "[ID!]!" }
        equipmentKindCodes: { type: "[Int!]!" }
        projectManagerIds: { type: "[ID!]!" }
        representativeIds: { type: "[ID!]!" }
        subRegionIds: { type: "[ID!]!" }
      ) {
        dispatchBranches: nodes(ids: $dispatchBranchIds) {
          __typename
          ... on Branch {
            id
            label
            deletedAt
          }
        }
        equipmentKinds(codes: $equipmentKindCodes) {
          ... on EquipmentKindLookup {
            id
            code
            label
          }
        }
        projectManagers: nodes(ids: $projectManagerIds) {
          __typename
          ... on Representative {
            id
            label
            deletedAt
          }
        }
        representatives: nodes(ids: $representativeIds) {
          __typename
          ... on Representative {
            id
            label
            deletedAt
          }
        }
        subRegions: nodes(ids: $subRegionIds) {
          __typename
          ... on SubRegion {
            id
            label
            region {
              description
            }
          }
        }
      }
    `,
    $key,
  );

  const responsiveGridFilters: ServiceCallResponsiveGridFilters_Regular = useMemo(() => {
    // Adding the extra data required by the responsive grid filter components to the filters should only be done once,
    // since this data is only fetched once during list page load. So once the filters have been modified by the user,
    // this extra data might not be up-to-date anymore, and overwriting the filters with it might corrupt the filters.
    // This is safe since the autocompletes which require this data also put it back in the filters on modification.
    if (filters instanceof ServiceCallResponsiveGridFilters_Regular) return filters;
    return filters.toResponsiveGridFilters({
      dispatchBranches: $data.dispatchBranches.filter(isDefined).filter(discriminate('__typename', 'Branch')),
      equipmentKinds: $data.equipmentKinds,
      projectManagers: $data.projectManagers.filter(isDefined).filter(discriminate('__typename', 'Representative')),
      representatives: $data.representatives.filter(isDefined).filter(discriminate('__typename', 'Representative')),
      subRegions: $data.subRegions.filter(isDefined).filter(discriminate('__typename', 'SubRegion')),
    });
  }, [filters, $data]);

  return (
    <ResponsiveGridFilters<ServiceCallResponsiveGridFilters_Regular>
      filters={responsiveGridFilters}
      emptyFilters={ServiceCallResponsiveGridFilters_Regular.EMPTY}
      onFiltersChange={handleFiltersChange}
      compact={compact}
      sx={(theme) => ({
        [theme.breakpoints.down('sm')]: { mx: '1rem' },
        [theme.breakpoints.up('md')]: { justifyContent: 'flex-start' },
      })}
      elements={(mode, state, setState) => [
        mode === 'inline' && (
          <TextSearchFilter
            key='fts'
            value={state.get('searchTerm')}
            placeHolder={t('search')}
            onChange={(searchTerm) => setState((prev) => prev.with({ searchTerm }))}></TextSearchFilter>
        ),
        mode === 'dialog' && (
          <JobKindFilter
            key='kind'
            value={state.get('kinds')}
            label={t('list.column.kind')}
            options={serviceCallKinds}
            onChange={(kinds) => setState((prev) => prev.with({ kinds }))}></JobKindFilter>
        ),
        mode === 'dialog' && (
          <CraneCapacityFilter
            key='capacity'
            value={state.get('capacities')}
            onChange={(value) => setState((prev) => prev.with({ capacities: value.map(({ capacity }) => capacity) }))}
          />
        ),
        mode === 'dialog' && (
          <EquipmentKindFilter
            key='equipmentKind'
            value={state.get('equipmentKinds')}
            onChange={(equipmentKinds) => setState((prev) => prev.with({ equipmentKinds }))}
          />
        ),
        mode === 'dialog' && (
          <JobStatusFilter
            key='status'
            value={state.get('statuses')}
            options={serviceCallStatuses.filter((s) => s !== 'locked')}
            onChange={(statuses) => setState((prev) => prev.with({ statuses }))}
            label={t('list.column.status')}
            renderChip={(option, handleDelete) => <ServiceCallStatusChip key={option} statuses={[option]} onDelete={handleDelete} />}
          />
        ),
        mode === 'dialog' && (
          <DispatchBranchFilter
            key='dispatchBranch'
            value={state.get('dispatchBranches')}
            onChange={(dispatchBranches) => setState((prev) => prev.with({ dispatchBranches }))}
          />
        ),
        mode === 'dialog' && (
          <SubRegionFilter
            key='subRegion'
            value={state.get('subRegions')}
            onChange={(subRegions) => setState((prev) => prev.with({ subRegions }))}
          />
        ),
        mode === 'dialog' && (
          <ProjectManagerFilter
            key='projectManager'
            value={state.get('projectManagers')}
            onChange={(projectManagers) => setState((prev) => prev.with({ projectManagers }))}
          />
        ),
        mode === 'dialog' && (
          <RepresentativeFilter
            key='representative'
            value={state.get('representatives')}
            onChange={(representatives) => setState((prev) => prev.with({ representatives }))}
          />
        ),
        mode === 'dialog' && (
          <ArrivalDateFilter
            key='arrivalDate'
            value={state.get('arrivalDate')}
            onChange={(arrivalDate) => setState((prev) => prev.with({ arrivalDate }))}
          />
        ),
      ]}
    />
  );
}
