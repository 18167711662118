import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { DateTime, DateTimeMaybeValid } from 'luxon';
import { parseDateTime, roundDateTimeToMinuteStep } from '../utils/dateTimeUtils';
import { ForwardedRef, forwardRef, RefAttributes, SetStateAction, useEffect, useState } from 'react';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay/PickersDay';
import { Box, TextFieldProps } from '@mui/material';
import {
  DateField,
  DateFieldProps,
  DatePicker,
  DatePickerProps,
  PickersDay,
  TimeField,
  TimeFieldProps,
  TimePickerProps,
} from '@mui/x-date-pickers-pro';
import { useCancellableSubscription } from '../hooks/useCancellableSubscription';
import graphql from 'babel-plugin-relay/macro';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { FormDateTimePicker_RoundedDateTimePickerQuery } from './__generated__/FormDateTimePicker_RoundedDateTimePickerQuery.graphql';
import { _throw } from '../utils/_throw';
import { useEffectEvent } from '../utils/effectUtils';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { mergeSx } from '../utils/mergeSx';

type DateInputPickerProps = {
  fieldOnly?: false;
} & Omit<DatePickerProps<DateTimeMaybeValid>, 'value' | 'onChange'>;
type DateInputFieldProps = {
  fieldOnly: true;
} & Omit<DateFieldProps<DateTimeMaybeValid>, 'value' | 'onChange'>;
type DateInputProps = DateInputPickerProps | DateInputFieldProps;

type TimeInputPickerProps = {
  fieldOnly?: false;
} & Omit<TimePickerProps<DateTimeMaybeValid>, 'value' | 'onChange'>;
type TimeInputFieldProps = {
  fieldOnly: true;
} & Omit<TimeFieldProps<DateTimeMaybeValid>, 'value' | 'onChange'>;
type TimeInputProps = TimeInputPickerProps | TimeInputFieldProps;

type RoundedDateTimePickerJoinedProps = {
  split?: false;
  value: DateTime<true> | null;
  onChange: (value: DateTime<true> | null) => void;
} & Omit<DateTimePickerProps<DateTimeMaybeValid>, 'value' | 'onChange'>;

type RoundedDateTimePickerSplitProps = {
  split: true;
  value: DateTime<true> | null;
  onChange: (value: DateTime<true> | null) => void;
  dateInput: DateInputProps;
  timeInput: TimeInputProps;
};

type RoundedDateTimePickerProps = RoundedDateTimePickerJoinedProps | RoundedDateTimePickerSplitProps;

/** Whether to fetch statutory holidays for the 3 closest months or years. */
const statutoryHolidaysFetchPeriod: 'year' | 'month' = 'year';

export const RoundedDateTimePicker = forwardRef(function RoundedDateTimePicker(
  { value, ...props }: RoundedDateTimePickerProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const env = useRelayEnvironment();
  const [, setSubscription] = useCancellableSubscription();

  // A date within the month currently displayed in the calendar
  const [displayedDate, setDisplayedDate] = useState<DateTimeMaybeValid | null>(null);
  // A date within the last fetched statutory holiday period
  const [fetchedDate, setFetchedDate] = useState<DateTime<true> | null>(null);
  // The list of statutory holidays for the current value of fetchedDate
  const [statutoryHolidays, setStatutoryHolidays] = useState<DateTime<true>[]>([]);

  /** Fetch statutory holidays for the 3 {@link statutoryHolidaysFetchPeriod} closest to the provided date. */
  const fetchStatutoryHolidays = useEffectEvent((dateTime: DateTime<true>) => {
    // Load 3 `statutoryHolidaysFetchPeriod` worth of statutory holidays to ensure we have enough data in case
    // DateTimePicker's `showDaysOutsideCurrentMonth` attribute is set to true, which can lead to some days outside the
    // current `statutoryHolidaysFetchPeriod` to be displayed. Also helps hiding data fetching latency.
    const startDate = dateTime.minus({ [statutoryHolidaysFetchPeriod]: 1 }).startOf(statutoryHolidaysFetchPeriod);
    const endDate = dateTime.plus({ [statutoryHolidaysFetchPeriod]: 1 }).endOf(statutoryHolidaysFetchPeriod);

    setSubscription(
      fetchQuery<FormDateTimePicker_RoundedDateTimePickerQuery>(
        env,
        graphql`
          query FormDateTimePicker_RoundedDateTimePickerQuery($where: StatutoryHolidayFilterInput) {
            statutoryHolidays(where: $where) {
              date
            }
          }
        `,
        {
          where: {
            and: [
              { date: { gte: startDate.toISODate() ?? _throw('Invalid date') } },
              { date: { lte: endDate.toISODate() ?? _throw('Invalid date') } },
            ],
          },
        },
      ).subscribe({
        next: (data) => {
          setStatutoryHolidays(
            data.statutoryHolidays.map(
              ({ date }) => parseDateTime(date) ?? _throw(`RoundedDateTimePicker: Invalid date string received from API: ${date}`),
            ),
          );
          setFetchedDate(dateTime);
        },
      }),
    );
  });

  useEffect(() => {
    if (displayedDate?.isValid && !fetchedDate?.hasSame(displayedDate, statutoryHolidaysFetchPeriod)) {
      fetchStatutoryHolidays(displayedDate);
    }
  }, [displayedDate, fetchStatutoryHolidays, fetchedDate]);

  const [dateValue, setDateValue] = useState<DateTimeMaybeValid | null>(value);
  const [timeValue, setTimeValue] = useState<DateTimeMaybeValid | null>(value);
  const [dateTimeValue, setDateTimeValue] = useState<DateTimeMaybeValid | null>(value);
  const [previousValue, setPreviousValue] = useState(value);

  if (value !== previousValue) {
    setDateValue(value);
    setTimeValue(value);
    setDateTimeValue(value);
    setPreviousValue(value);
  }

  const handleChange = (val: DateTimeMaybeValid | null) => {
    if (val && !val.isValid) return;
    props.onChange?.(val);
  };

  const handleDateChange = (val: DateTimeMaybeValid | null) => {
    setDateValue(val);
    handleChange(val);
  };

  const handleTimeChange = (val: DateTimeMaybeValid | null) => {
    setTimeValue(val);
    handleChange(val);
  };

  const handleDateTimeChange = (val: DateTimeMaybeValid | null) => {
    setDateTimeValue(val);
    handleChange(val);
  };

  const minutesStep = props.split ? props.timeInput.minutesStep : props.minutesStep;

  const handleBlur = (val: DateTimeMaybeValid | null, setVal: (action: SetStateAction<DateTimeMaybeValid | null>) => void) => {
    if (!val?.isValid) {
      props.onChange?.(null);
      setVal(null);
      return;
    }

    if (!minutesStep) return;

    const rounded = roundDateTimeToMinuteStep(val, minutesStep);
    if (rounded.isValid && !rounded.equals(val)) {
      props.onChange?.(rounded);
      setVal(rounded);
    }
  };

  if (props.split) {
    const { fieldOnly: dateInputFieldOnly, ...dateInputProps } = props.dateInput;
    const dateSlotPropsTextField = dateInputProps.slotProps?.textField as TextFieldProps | undefined;

    const { fieldOnly: timeInputFieldOnly, ...timeInputProps } = props.timeInput;
    const timeSlotPropsTextField = timeInputProps.slotProps?.textField as TextFieldProps | undefined;

    return (
      <Box sx={{ display: 'flex', gap: '0.25rem' }}>
        {dateInputFieldOnly ? (
          <DateField
            {...dateInputProps}
            onChange={handleDateChange}
            clearable
            value={dateValue}
            slotProps={{
              ...dateInputProps.slotProps,
              textField: {
                ...dateSlotPropsTextField,
                // Fix alignment issue that occurs when field doesn't have a label.
                // See the 0.25rem margin top set for MuiTextField in theme.ts.
                sx: mergeSx(dateInputProps.label ? null : { pt: '0.25rem' }, dateSlotPropsTextField?.sx),
                error: dateSlotPropsTextField?.error || dateValue?.isValid === false,
                onBlur: () => handleBlur(dateValue, setDateValue),
              },
            }}
          />
        ) : (
          <DatePicker
            {...dateInputProps}
            onChange={handleDateChange}
            value={dateValue}
            slotProps={{
              ...dateInputProps.slotProps,
              textField: {
                ...dateSlotPropsTextField,
                // Fix alignment issue that occurs when field doesn't have a label.
                // See the 0.25rem margin top set for MuiTextField in theme.ts.
                sx: mergeSx(dateInputProps.label ? null : { pt: '0.25rem' }, dateSlotPropsTextField?.sx),
                error: dateSlotPropsTextField?.error || dateValue?.isValid === false,
                onBlur: () => handleBlur(dateValue, setDateValue),
              },
            }}
          />
        )}
        {timeInputFieldOnly ? (
          <TimeField
            {...timeInputProps}
            ref={ref}
            value={timeValue}
            onChange={handleTimeChange}
            slotProps={{
              ...timeInputProps.slotProps,
              textField: {
                ...timeSlotPropsTextField,
                // Fix alignment issue that occurs when field doesn't have a label.
                // See the 0.25rem margin top set for MuiTextField in theme.ts.
                sx: mergeSx(timeInputProps.label ? null : { pt: '0.25rem' }, timeSlotPropsTextField?.sx),
                error: timeSlotPropsTextField?.error || timeValue?.isValid === false,
                onBlur: () => handleBlur(timeValue, setTimeValue),
              },
            }}
          />
        ) : (
          <TimePicker
            {...timeInputProps}
            ref={ref}
            value={timeValue}
            onChange={handleTimeChange}
            slotProps={{
              ...timeInputProps.slotProps,
              textField: {
                ...timeSlotPropsTextField,
                // Fix alignment issue that occurs when field doesn't have a label.
                // See the 0.25rem margin top set for MuiTextField in theme.ts.
                sx: mergeSx(timeInputProps.label ? null : { pt: '0.25rem' }, timeSlotPropsTextField?.sx),
                error: timeSlotPropsTextField?.error || timeValue?.isValid === false,
                onBlur: () => handleBlur(timeValue, setTimeValue),
              },
            }}
          />
        )}
      </Box>
    );
  }

  return (
    <DateTimePicker<DateTimeMaybeValid>
      {...props}
      ref={ref}
      value={dateTimeValue}
      onChange={handleDateTimeChange}
      onOpen={() => {
        setDisplayedDate(value ?? DateTime.now());
      }}
      onMonthChange={(month) => {
        setDisplayedDate(month);
      }}
      onYearChange={(year) => {
        setDisplayedDate(year);
      }}
      slots={{ day: RoundedDateTimePickerDay }}
      slotProps={{
        ...props.slotProps,
        actionBar: {
          actions: ['clear', 'cancel', 'accept'],
          sx: {
            'button:first-of-type': {
              mr: 'auto', // left align the first action in the actionBar, in this case the clear button
            },
          },
        },
        field: {
          clearable: true,
        },
        textField: {
          ...props.slotProps?.textField,
          error: (props.slotProps?.textField as TextFieldProps | undefined)?.error || dateTimeValue?.isValid === false,
          onBlur: () => handleBlur(dateTimeValue, setDateTimeValue),
        },
        toolbar: {
          sx: {
            '.MuiDateTimePickerToolbar-timeDigitsContainer': {
              alignItems: 'center', // fix time digits & colon alignment issue in the toolbar
            },
          },
        },
        day: {
          ...props.slotProps?.day,
          ...{ statutoryHolidays }, // spreading is used to avoid a type error as statutoryHolidays is a custom prop
        },
      }}
    />
  );
});

function RoundedDateTimePickerDay({
  statutoryHolidays = [],
  ...props
}: PickersDayProps<DateTimeMaybeValid> & RefAttributes<HTMLButtonElement> & { statutoryHolidays?: DateTime<true>[] }) {
  const { isWeekend } = props.day;
  const isStatutoryHoliday = () => statutoryHolidays.some((date) => date.hasSame(props.day, 'day'));
  const displayBackground = (props.showDaysOutsideCurrentMonth || !props.outsideCurrentMonth) && (isWeekend || isStatutoryHoliday());
  const isPastDate = props.day < DateTime.now().startOf('day');

  return (
    <Box sx={(theme) => ({ backgroundColor: displayBackground ? theme.palette.background.default : undefined })}>
      <PickersDay {...props} sx={(theme) => ({ color: isPastDate ? theme.palette.text.disabled : undefined })} />
    </Box>
  );
}
